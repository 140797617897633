<template>
  <div class="device-container">
    <shuffl title="装备管理现状" :lists="shuffleLists1"></shuffl>

    <div class="hardware">
      <h2>硬件设备</h2>

      <div>
        <div v-for="(item, index) in ['电子标签', '手持机', '高频阅读器']" :key="index">
          <img :src="require('@/assets/images/product/device/hardware-' + index + '.png')" :alt="item" />
          <span>{{ item }}</span>
        </div>
      </div>
    </div>

    <shuffl class="system-key" title="系统重点功能" :lists="shuffleLists2"></shuffl>

    <div class="device-video">
      <video-player class="device-video_box" :options="playerOptions"></video-player>
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'

import Shuffl from './components/Shuffl'

function getImg(index) {
  return require('@/assets/images/product/device/swiper-' + index + '.png')
}

const lists1 = [
  {
    title: '平时管理效率低',
    subTitles: [
      '装备种类繁多，当前队站对于装备管理还停留在人工阶段：进出库登记靠手写、车辆与仓库盘点靠人数、装备状态上报靠对讲',
      '管理效率不高、数据不准',
      '同时装备体系混乱，知识库缺失，标准不一，无法最大化发挥装备的作用'
    ]
  },
  {
    title: '战时调度部署难',
    subTitles: ['装备状态（数量、位置与可用性）获取不实时', '战时装备调度与部署缺乏数据支撑，无法实现战时装备的快速调度、位置监控、数量查询和使用状态采集', '战场装备智能辅助决策亟待加强']
  },
  {
    title: '战勤保障粗放',
    subTitles: ['战保仓库管理粗放，自动化智能化程度不高，无法实现重大灾情突发时的快速响应', '战保物资采购与使用不同步（先采购的后使用）导致物资浪费', '战勤保障精细化水平有待提高']
  },
  {
    title: '装备决策数据缺失',
    subTitles: ['装备流转、使用、维修、报废等生命周期明细数据缺乏沉淀', '各级指挥员在装备采购选型、装备预算制定、装备支出漏洞自查等方面缺乏有力数据支撑', '总队及各支队装备大数据体系尚未建立']
  }
]

const lists2 = [
  {
    title: '业务价值1：管装备',
    subTitles: [
      {
        isAry: true,
        texts: [
          { text: '装备管理模块充当' },
          { text: '装备管家', isStrong: true },
          { text: '角色，实现对车辆、器材和药剂的快捷入库登记，车辆日检对车载装备实现无感式自动盘点，始终保持战备水准。' },
          { text: '移动端提供装备流转流程的快速审批和记录。', isStrong: true },
          { text: '装备知识库对装备分类、名称标准、装备性能参数进行全方位管理，实现' },
          { text: '“命名标准、型号统一、参数明确、手册可查”', isStrong: true },
          { text: '的目标；装备配备可对队站配备标准、车载装备标准进行维护，同时可以基于事故灾害类型定义所需的装备组合，' },
          { text: '方便战时一键调度。', isStrong: true }
        ]
      }
    ]
  },
  {
    title: '业务价值2：查装备',
    subTitles: [
      {
        isAry: true,
        texts: [
          { text: '平台通过装备洞察功能全方位透视支队装备家底，快速回答' },
          { text: '“有什么、有多少、在哪里、怎么样、缺什么、缺多少、用了多少”', isStrong: true },
          {
            text:
              '的问题。装备搜索可根据关键词快速定位具体装备，可通过组织机构、装备类型、事故类型多维度查询。基于城市消防站建设标准自动计算各队站装备的缺编情况。装备动态以图形的方式汇总指定时间内器材、药剂的使用情况及车辆轨迹。'
          }
        ]
      }
    ]
  },
  {
    title: '业务价值3：用装备',
    subTitles: [
      '基于物联网技术实现装备的互联互通和状态实时上报',
      '打造基于事故灾害类型和距离的智能装备调度体系',
      '实现战时出动消防车定位，到达时间及路线规划，火场装备实时管理等功能',
      '构建灾情现场装备使用数据实时采集通道，为灭火救援力量部署提供辅助决策'
    ]
  },
  {
    title: '业务价值4：评装备',
    subTitles: [
      {
        isAry: true,
        texts: [
          { text: '装备决策整合装备全生命周期数据，' },
          { text: '实现“用数据说话、用数据管理、用数据决策”的目标', isStrong: true },
          { text: '通过整合装备新购、维修保养、报废等历史数据，为装备预算提供参考支撑；汇总一线指战员对装备在实战中的评价，' },
          { text: '遵循“实践出真知、优胜劣汰”的基本准则', isStrong: true },
          { text: '客观评估各装备的效能、需要保养的装备列表，为后续装备选型、保养提供数据支撑。' }
        ]
      }
    ]
  }
]

const shuffleLists1 = lists1.map((item, index) => ({
  ...item,
  img: getImg(index)
}))

const shuffleLists2 = lists2.map((item, index) => ({
  ...item,
  img: getImg(index + 4)
}))

export default {
  name: 'Device',

  components: {
    Shuffl,
    videoPlayer
  },

  data() {
    return {
      shuffleLists1,
      shuffleLists2,

      playerOptions: {
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        sources: [
          {
            type: 'video/mp4',
            src: 'https://socialize.zkyunteng.cn/fronts/equip/upload/equip_system.mp4' //url地址
          }
        ],
        poster: '',
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.system-key /deep/ .swiper-slide > div {
  margin-top: 0 !important;
}

.device-container {
  color: rgba($color: #000000, $alpha: 0.8);

  h2 {
    font-size: 32px;
  }

  p {
    line-height: 1.5;
  }

  & > div:not(:last-of-type) {
    padding: 70px 30px;
    box-sizing: border-box;
    @include flex-center(center, column);
  }

  @media screen and (max-width: 768px) {
    & > div:not(:last-of-type) {
      padding: 30px 20px;
      box-sizing: border-box;
      @include flex-center(center, column);
    }
  }
}

.device-video {
  width: 100%;
  text-align: center;
  text-align: -webkit-center;
  margin-bottom: 50px;
  &_box {
    width: 70%;
    object-fit: fill;

    &/deep/ .vjs-big-play-button {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    &_box {
      width: 100%;
    }
  }
}

.hardware {
  background-color: #0e57a2;
  text-align: center;

  h2 {
    color: #fff;
    font-size: 32px;
  }

  & > div {
    @include flex-center(center);
    flex-wrap: wrap;
    & > div {
      width: 382px;
      overflow: hidden;
      margin-top: 36px;
      margin-right: 24px;
      box-shadow: 2px 2px 8px 0px rgba(192, 192, 192, 0.4);

      &:last-child {
        margin-right: 0;
      }

      &:hover img {
        transform: scale(1.1);
      }
    }

    img {
      @include size(100%, 216px);
      vertical-align: top;
      transition: transform 0.2s ease;
    }

    span {
      display: inline-block;
      @include size(100%, 57px);
      line-height: 57px;
      text-align: center;
      background-color: #f8f8f8;

      position: relative;
      z-index: 1;
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 20px;
    }

    & > div {
      & > div {
        width: 100%;
        // margin: 0 20px;
        margin: 27px 0 0 0;
        // margin-right: 0;
        box-shadow: none;

        span {
          @include size(100%, 30px);
          position: relative;
          color: #fff;
          text-align: center;
          font-size: 14px;
          line-height: 1;
          background: none;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
