<template>
  <div class="introduce">
    <h2>{{ title }}</h2>
    <ul>
      <li class="hvr-float" v-for="(items, index) in lists" :key="index">
        <p v-for="(item, subIndex) in items" :key="subIndex">{{ item }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: String,

    lists: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.introduce {
  width: 100%;
  padding: 70px 30px 40px;
  background-color: #fff;
  box-sizing: border-box;
  @include flex-center(center, column);

  h2 {
    font-size: 32px;
    color: rgba(0, 0, 0, 0.8);
  }

  & > ul {
    margin-top: 23px;
    @include flex-center(center);
    align-items: stretch;
    flex-wrap: wrap;

    li {
      margin-top: 50px;
      margin-right: 40px;
      width: 282px;
      text-align: center;
      @include flex-center(flex-start, column);
      transition: all 0.2s ease;
      cursor: default;

      border-radius: 12px;
      padding: 24px;
      box-shadow: 0px 2px 16px 0px rgba(91, 91, 91, 0.15);

      p {
        text-align: center;
        line-height: 1.8;
      }

      p:first-child {
        font-size: 20px;
        margin-bottom: 24px;
        font-weight: bold;
        color: #333;
      }

      p:not(:first-child) {
        margin-bottom: 16px;
        width: 100%;
        color: rgba($color: #000000, $alpha: 0.6);
      }

      p:last-child {
        margin-bottom: 0;
      }

      &:last-child {
        margin-right: 0;
        // p {
        //   text-align: center;
        // }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 30px 20px;

    h2 {
      font-size: 20px;
    }

    & > ul {
      margin-top: 0;

      li {
        width: 100%;
        margin-top: 25px;
        margin-right: 0;
        text-align: left;
        align-items: flex-start;
        font-size: 12px;

        p {
          text-align: left;
        }

        p:first-child {
          font-size: 14px;
          margin-bottom: 9.5px;
          font-weight: bold;
        }

        p:not(:first-child, :last-child) {
          margin-bottom: 8px;
        }

        &:last-child p {
          text-align: left;
        }
      }
    }
  }
}
</style>
