<template>
  <div class="home">
    <!-- 轮播 -->
    <div :id="anchorId" class="swiper-container" style="width: 100%">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in [1, 2, 3, 4]" :key="item">
          <div
            class="swiper-content"
            :style="{
              'background-image': 'url(https://socialize.zkyunteng.cn/fronts/website/home/home-' + (item - 1) + '.png)'
            }"
          ></div>
          <banner-center v-if="item === 1"></banner-center>
          <banner-left v-else :mode="item"></banner-left>
        </div>
      </div>

      <!-- 分页器 -->
      <div class="swiper-pagination">
        <img class="swiper-pagination-prev" src="@/assets/images/prev.png" @click="onSwiperPreOrNext('slidePrev')" />
        <span
          :class="{
            'swiper-pagination_item': true,
            'swiper-pagination_item-active': item === crurrentSwiperIndex
          }"
          v-for="item in [0, 1, 2, 3]"
          :key="item"
          @click="onSwiperTo(item)"
          >0{{ item + 1 }}</span
        >
        <img class="swiper-pagination-next" src="@/assets/images/next.png" @click="onSwiperPreOrNext('slideNext')" />
      </div>
    </div>

    <!-- 产品特色 -->
    <div class="product-special">
      <div class="product-special-contain">
        <div class="product-special-contain-header">
          <h2>产品特色</h2>
          <p>构建在大数据AI、物联网技术之上的一站式城市应急消防产品解决方案</p>
          <p>“四化”目标：消防安全数字化、灭火救援精准化、队伍管理规范化、社会防火技术化</p>
        </div>
        <div class="content">
          <ul>
            <li v-for="(item, index) in productAccordion" :key="index" :class="{ act: accordionNow === index }" @click="accordionNow = index">
              <img class="background" :src="'https://socialize.zkyunteng.cn/fronts/website/home/product-features-' + (index + 1) + '.png'" alt="产品特色" />
              <dl v-if="accordionNow === index">
                <dt>{{ item.title }}</dt>
                <dd>{{ item.describe }}</dd>
              </dl>
              <div v-else>{{ item.title }}</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="product-special-mark"></div>
    </div>

    <!-- 产品体系 -->
    <zk-swiper id="systemZkSwiper" title="产品体系" :sub-title="systemSubTitle" :lists="productSystem"></zk-swiper>

    <shuffl id="systemShuffl" title="产品体系" :sub-title="systemSubTitle" :lists="productSystem2Lists"></shuffl>
  </div>
</template>

<script>
import BannerCenter from './components/banner-center'
import BannerLeft from './components/banner-left'

import ZkSwiper from '@/components/Swiper'
import Shuffl from '../Products/components/Shuffl'

import Swiper, { Navigation, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

// swiper停留时间
const SWIPER_DELAY = 8000
// 动画过渡时间
const SWIPER_SPEED = 1000

export default {
  components: {
    ZkSwiper,
    BannerLeft,
    BannerCenter,
    Shuffl
  },

  data() {
    return {
      productAccordion: [
        {
          title: '一站式闭环',
          describe: '中科云腾的产品服务政府、消防队和社会单位三大主体，覆盖防火监督、队伍管理、营区战训和灭火指挥四大环节'
        },
        {
          title: '软硬结合',
          describe: '基于行业特点自研的智能设备可实现消防场景下数据的高效、精准采集，与软件平台无缝对接，打造消防软硬一体高效平台'
        },
        {
          title: '数据驱动',
          describe: '产品始于数据、基于数据、终于数据，全程围绕城市应急消防大数据的采集、汇聚、加工和应用进行，构建城市安全数据资产'
        }
      ],
      productSystem: [
        {
          img: 'https://socialize.zkyunteng.cn/fronts/website/home/product-system-1.png',
          title: '服务城市应急',
          subTitle: '城市应急安全新基建',
          descList: ['数据：城市应急安全数据中台', '智能：城市消防大脑']
        },
        {
          img: 'https://socialize.zkyunteng.cn/fronts/website/home/product-system-2.png',
          title: '服务消防救援队',
          subTitle: '消防队站平战一体化解决方案',
          descList: ['接警：119辅助接处警系统', '训练：消防队站灭火救援数字化预案管理平台', '管理：装备实战管理平台', '作战：灭火救援实战指挥与辅助决策平台']
        },
        {
          img: 'https://socialize.zkyunteng.cn/fronts/website/home/product-system-3.png',
          title: '服务社会单位',
          subTitle: '社会单位应急安全综合解决方案',
          descList: ['预案：社会单位数字化应急预案管理平台', '监控：社会单位智慧消防物联网监控云平台']
        }
      ],

      accordionNow: 0,

      crurrentSwiperIndex: 0,

      anchorId: 'swiper',

      productSystem2Lists: [],
      systemSubTitle:
        '中科云腾致力于打造应急消防一站式产品解决方案，提升消防救援效率，保障社会单位安全。产品和服务体系覆盖消防救援单位与社会生产经营单位在防火、灭火、战训与演练的各个环节，实现城市消防资源的互联互通、汇聚城市消防数据，构建城市消防数据中台与智慧消防大脑，逐步解决城市消防痛点与难点。'
    }
  },

  mounted() {
    this.productSystem2Lists = this.productSystem.map(item => ({
      img: item.img,
      title: item.title,
      subTitles: [item.subTitle, ...item.descList]
    }))
    this.$nextTick(() => {
      this.initSwiper()
    })
  },

  methods: {
    initSwiper() {
      Swiper.use([Navigation, Autoplay])
      this.swiper = new Swiper('#' + this.anchorId, {
        autoplay: {
          delay: SWIPER_DELAY,
          disableOnInteraction: false
        },
        grabCursor: true,
        loop: true,
        speed: SWIPER_SPEED,
        on: {
          slideChange: ({ realIndex }) => {
            this.crurrentSwiperIndex = realIndex
          }
        }
      })
    },

    onSwiperTo(index) {
      this.crurrentSwiperIndex = index
      this.swiper.slideToLoop(index, SWIPER_SPEED, false)
    },

    onSwiperPreOrNext(type) {
      this.swiper[type](SWIPER_SPEED)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.lastPage {
  height: 100vh;
}

@keyframes bgShow {
  from {
    height: 0;
  }
  to {
    height: 144px;
  }
}

@keyframes dtTxtShow {
  from {
    bottom: 0;
  }
  to {
    bottom: 64px;
  }
}

@keyframes ddTxtShow {
  from {
    bottom: 0;
  }
  to {
    bottom: 32px;
  }
}

@keyframes bgShow2 {
  from {
    height: 0;
  }
  to {
    height: 144px;
  }
}

@keyframes dtTxtShow2 {
  from {
    bottom: 0;
  }
  to {
    bottom: 64px;
  }
}

@keyframes ddTxtShow2 {
  from {
    bottom: 0;
  }
  to {
    bottom: 12px;
  }
}

.swiper-container {
  height: 100vh;
  width: 100vw;
  .swiper-wrapper {
    .swiper-slide {
      width: 100vw;
      height: 100vh;
      background-color: #000;
      text-align: center;

      .swiper-content {
        width: 100vw;
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
}

.home {
  width: 100%;
  overflow-x: hidden;
  .banner-section {
    height: unset !important;
  }
  .product-special {
    width: 100%;
    height: 800px;
    background: url('~@/assets/images/product-features-bg.png') no-repeat center center;
    background-size: cover;
    position: relative;
    &-mark {
      background-color: rgba($color: #000000, $alpha: 0.2);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &-contain {
      width: 100%;
      height: 100%;
      // padding: 9% 0 11.13%;
      padding: 72px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 1;
      .content {
        width: 1200px;
        height: 500px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          li {
            width: 250px;
            background: brown;
            height: 500px;
            cursor: pointer;
            transition: all 0.4s ease;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            z-index: 1;
            position: relative;
            overflow: hidden;
            .background {
              width: auto;
              height: 100%;
              filter: blur(2px);
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              z-index: 2;
            }
            &::after {
              content: '';
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              background: rgba(0, 0, 0, 0.62);
              z-index: 3;
            }
            & > div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 28px;
              font-weight: 600;
              color: rgba(255, 255, 255, 0.95);
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              right: 0;
              margin: auto;
              z-index: 11;
            }
          }
          .act {
            width: 652px;
            position: relative;
            overflow: hidden;
            &::after {
              content: none;
            }
            .background {
              filter: none;
            }
            dl {
              width: 100%;
              position: absolute;
              bottom: 0px;
              left: 0px;
              text-align: left;
              z-index: 11;
              background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.65) 100%);
              padding: 24px;
              box-sizing: border-box;
              height: 0;
              animation: bgShow 0.5s ease;
              animation-fill-mode: forwards;
              dt {
                font-size: 28px;
                font-weight: 700;
                margin-bottom: 1.6%;
                animation: dtTxtShow 0.5s ease;
              }
              dd {
                font-size: 16px;
                font-weight: 400;
                padding-right: 32px;
                box-sizing: border-box;
                width: 604px;
                animation: ddTxtShow 0.6s ease;
              }
              dt,
              dd {
                color: rgb(35, 35, 35);
                animation-fill-mode: forwards;
                position: absolute;
                left: 32px;
                bottom: 0;
              }
            }
          }
        }
      }
      &-header {
        text-align: center;
        margin-bottom: 45px;
        h2 {
          font-size: 32px;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 2.38%;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
          margin-bottom: 0.75%;
        }
      }
    }

    @media screen and (max-width: 768px) {
      height: 100%;
      background: #f0f2f5;
      &-mark {
        display: none;
      }

      &-contain {
        padding: 0 25px;

        &-header {
          text-align: center;
        }

        .content {
          @include size(100%);
          ul {
            justify-content: center;
            flex-direction: column;
            li {
              width: 100% !important;
              height: 100px !important;
              transition: height 0.2s ease;
              margin-bottom: 12px;
              &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: rgba($color: #003fa3, $alpha: 0.7) !important;
                z-index: 3;
              }

              &.act {
                height: 250px !important;
                dd,
                dt {
                  width: 100% !important;
                  left: 12px;
                  line-height: 1.5;
                }
                dl {
                  padding: 8px 12px;
                  animation-name: bgShow2;

                  dt {
                    font-size: 15px;
                    animation-name: dtTxtShow2;
                  }
                  dd {
                    font-size: 12px;
                    animation-name: ddTxtShow2;
                  }
                }
              }

              & > div {
                font-size: 16px;
                color: #fff;
              }
            }
          }
        }
      }

      h2 {
        font-size: 20px;
        margin-top: 29px;
        margin-bottom: 0;
        color: rgba($color: #000000, $alpha: 0.8);
        margin-bottom: 20px;
      }

      p {
        font-size: 12px;
        color: rgba($color: #000000, $alpha: 0.7);
        line-height: 1.5;
      }
    }
  }
  .section {
    img {
      width: 100%;
    }
    .banner {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }

  #systemShuffl {
    display: none;
  }

  @media screen and (max-width: 768px) {
    #systemZkSwiper {
      display: none;
    }

    #systemShuffl {
      display: flex;
    }
  }
}
.swiperPag {
  width: 4.5rem;
  height: 0.07rem;
  border-radius: 0.04rem;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: rgba($color: #000000, $alpha: 0.8);
}

.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 55px;
  transform: translateX(-50%);
  z-index: 1;

  span {
    cursor: pointer;
  }

  &-prev,
  &-next {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  &_item {
    font-size: 16px;
    color: rgba($color: #fff, $alpha: 0.4);
    padding: 10px 6px;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    &-active {
      color: #fff;
    }
  }
}
</style>
