<template>
  <introduce :title="'系统介绍'" :lists="lists"></introduce>
</template>

<script>
import Introduce from './components/Introduce'
export default {
  name: 'safe',
  components: {
    Introduce
  },

  data() {
    return {
      lists: [
        ['功能', '建立城市级应急领域大数据中心，提供城市应急安全数据统一采集、统一清洗、统一生产和统一服务开放功能，注智应急救援'],
        [
          '数据特色',
          '三维实景数据，实现城市数字孪生',
          '社会单位建筑结构数据，让应急救援落到具体场景',
          '社会单位生产经营安全基础数据（消防设施、危化品、重点部位），风险评估无死角',
          '物联网实时监控数据（火灾自动报警、用电预警、消防通道视频），使得城市安全实时在线',
          '城市交通、气象、市政管网、自然水源等数据大融合'
        ],
        [
          '功能特色',
          '非结构化和半结构化数据储存与处理优化（城市安全数据，大部分为非结构或半结构化数据）',
          '配备安全数据采集客户端，方便应急救援队伍（消防队伍）快捷采集社会单位安全数据，数据采集与安全监管结合',
          '基于人、场、物的安全数据关联融合生产流程、快速透明的数据共享接口',
          '可视化的数据治理与运维监控'
        ],
        ['目标', '为城市“智慧应急”赋能']
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
