<template>
  <div class="alarm-container">
    <div class="alarm-container_item" v-for="(item, index) in ['行业痛点与需求', '系统架构']" :key="index">
      <h2>{{ item }}</h2>
      <img :src="require('@/assets/images/product/119/119-' + index + '.png')" :alt="item" />
    </div>

    <introduce title="业务价值" :lists="lists"></introduce>
  </div>
</template>

<script>
import Introduce from './components/Introduce'
export default {
  name: 'Alarm',
  components: {
    Introduce
  },

  data() {
    return {
      lists: [
        ['报警人', '解决报警人慌乱中描述不清位置和现场情况、紧急情况下的沟通障碍等问题', '人性化指导，确保报警人快速得到专业化救助'],
        ['社会', '接警时能及时掌握报警人的准确位置，快速出警，最大程度挽救人民群众的生命和财产安全', '减轻接警员的工作量，减少通话时长，提升警务效能'],
        ['消防部门', '提高处置救援效率，降低灾害事故影响，维护社会安定和谐', '创新探索构建“互联网+应急救援”的快速出警模式，助力智慧城市建设']
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.alarm-container {
  color: rgba($color: #000000, $alpha: 0.8);

  h2 {
    font-size: 32px;
    text-align: center;
    font-weight: bold;
  }

  p {
    line-height: 1.5;
  }

  &_item {
    text-align: center;
    padding: 30px 15px 40px;

    img {
      margin-top: 50px;
    }

    &:nth-child(1) img {
      @include size(826px, 295px);
    }
    &:nth-child(2) img {
      @include size(1197px, 410px);
    }
  }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 20px;
    }

    &_item {
      img {
        margin-top: 20px;
      }
      &:nth-child(1) img {
        @include size(auto, 124px);
      }
      &:nth-child(2) img {
        @include size(auto, 118px);
      }
    }

    .footer {
      height: 152px !important;
    }
  }
}
</style>
