const getImg = type => `https://socialize.zkyunteng.cn/fronts/website/${type}.jpg`

export const topContents = {
  Safe: [
    '城市应急安全数据中台',
    '通过物联网感知设备、无人机、单位上报等多种方式，全面采集城市应急消防安全基础数据，在统一标准下进行清洗、关联和整合，沉淀为城市应急安全数据资产，为各级单位应急响应提供数据支撑',
    getImg('safe')
  ],
  Alarm: [
    '119互联网辅助接处警系统',
    '基于互联网渠道实现事故灾害地点的精准定位以及与报警人员的实时音视频沟通，并通过调用互联网的多维服务实现消防救援力量的智能调度和出警协同，从而解决传统接处警中的定位不准、警情掌握不详、力量调派不精准、出警协同不到位的一些列问题',
    getImg('119')
  ],
  Device: [
    '装备实战管理平台',
    '装备实战管理平台以先进的物联网、大数据和人工智能技术为基础，为装备贴上电子标签，通过无线感知设备采集装备实时状态，构建装备全生命周期数据视图，实现对消防救援装备平时的精细化管理和战时的高效调度使用。从而降低装备管理成本、提升装备技战术水平，全方位解决装备的管理、查询、实战和评价问题。',
    getImg('device')
  ],
  Fireplan: [
    '灭火救援数字化预案管理平台',
    '为消防救援队伍构建城市灭火救援数字孪生环境，实现基于电子沙盘环境的灭火救援过程推演和管理，模拟灾情发生、企业处置、力量调派与力量部署等环节，真正做到战训一体化，防火灭火一体化、消防队与社会单位一体化。',
    getImg('fire')
  ],
  Society: [
    '社会单位数字化应急预案管理平台',
    '基于消防救援标准，为社会单位建筑物构建三维场景模型，标注消防设施基础数据，三维场景模型与社会单位日常防火工作、消防监控及应急策略联动，确保社会单位“防火责任到位、应急处置科学”',
    getImg('society')
  ],
  Monitor: [
    '社会单位智慧消防物联网监控云平台',
    '为社会单位消防安全监控设备提供快捷、高效、可视的监控平台，兼容多种通讯协议，即时安装、即时接入、即时监控，综合报警历史数据评估火灾风险，追踪消防隐患，解决社会单位过往“只报警不解决”的难题',
    getImg('monitor')
  ]
}

const HOST = 'https://socialize.zkyunteng.cn/fronts/website/product'

const alarm = HOST + '/alarm.png'
const device = HOST + '/device.png'
const fireplan = HOST + '/fireplan.png'
const monitor = HOST + '/monitor.png'

export const footerContents = {
  Alarm: {
    title: '产品展示',
    img: alarm
  },
  Device: {
    title: '应用案例',
    content:
      '某消防支队积极响应总队号召，提升装备管理信息化水平，推动装备实战管理平台上线使用，大大提升了装备日常管理自动化水平，解放了消防员的双手。同时一改过往装备平时与战时脱离的现象，使得装备管理由平时延展到战时，大幅度提升了战时装备使用的实时性、科学性和高效性。',
    img: device
  },
  Fireplan: {
    title: '预案管理与决策',
    content: '对辖区内重点单位预案的覆盖率，基础数据质量进行统计呈现；依据领导层级对提交的预案进行审批，确保预案制作符合规范',
    img: fireplan
  },
  Monitor: {
    title: '社会单位的自主安全管理——智慧消防物联网监控云平台',
    img: monitor
  }
}
