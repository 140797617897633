const getImg = (index, isJpeg = false) => `https://socialize.zkyunteng.cn/fronts/website/talent-plan/${index}${isJpeg ? '.jpg' : '.png'}`

const TYPE_STATE = {
  NEW: 0,
  FIRE: 1,
  SOCIETY: 2
}

export const listsData = [
  {
    id: 0,
    type: [TYPE_STATE.NEW, TYPE_STATE.FIRE],
    title: '装备实战管理平台应用案例',
    avatar: getImg(0, true),
    content:
      '某消防救援支队消防装备管理一直以来处于手工管理的状态，装备底数不清，指挥调度困难。为解决上述问题，该支队引进部署中科云腾装备实战管理平台，为重要装备绑定电子标签，实现装备身份数字化，并通过装备实战管理平台实现了“查装备、管装备、用装备和评装备”，解放了消防员双手，大幅度提升了装备管理的效率和实战水平。'
  },
  {
    id: 1,
    type: [TYPE_STATE.NEW, TYPE_STATE.SOCIETY],
    title: '社会单位数字化应急预案平台应用案例',
    avatar: getImg(1),
    content:
      '某大型火力发电厂在消防安全方面非常重视，厂区配有专职消防队和大量的灭火救援装备，也制定了完善的事故处理预案，这些预案停留在文本阶段，厂区安全人员很难基于文本预案进行演练，同时文本预案冗长，无法给到厂区所有工作人员学习，限制了安全知识的传播。该电厂在调研后，开通了中科云腾社会化数字预案管理平台账号，一方面将原来的文本预案上传到平台存档；另一方面在平台内加载厂区三维模型，在三维模型上将原来文本预案中的处置指引转化为三维动态效果，并基于三维预案电子沙盘进行处置演练，提升了演练效果。三维预案可通过H5页面分享给厂区所有工作人员，真正实现了全员安全教育。电厂的消防基础数据同步到所属的消防大队，实现了数据联动，为后续救援提供有力保障，电厂安全更有保障'
  },
  {
    id: 2,
    type: [TYPE_STATE.NEW, TYPE_STATE.FIRE],
    title: '119互联网辅助接处警平台应用案例',
    avatar: getImg(3),
    content:
      '传统119接警系统存在定位不准和沟通不形象两大难题，通过运营商基站定位获得的地址与真实地址往往有一定的差距（几十米到几百米不等），而且语音沟通无法获取现场的图像视频，报警人描述在紧急情况下可能存在失真的情况。为解决上述问题，某消防支队119指挥中心安装中科云腾119互联网辅助接处警系统，该系统通过基站和GPS双重定位，可精确锁定灾情位置，同时通过在线视频电话真实了解灾情现场情况，为出警调度提供支撑。该系统部署以来，每日为该消防救援支队提供上百次警情处置服务，获得了接警工作人员的大力好评。'
  },
  {
    id: 3,
    type: [TYPE_STATE.NEW, TYPE_STATE.FIRE],
    title: '灭火救援数字化预案系统应用案例',
    avatar: getImg(2),
    content:
      '某消防救援大队辖区内有很多高危企业（消防重点单位），包括石化储运企业、电力能源企业等，上级要求针对这些重点单位制定相应的灭火救援预案，以便灾情发生时能针对性处置，料敌于先。传统的灭火救援预案都是文本预案，存在不形象、不简洁、实际操作难的问题，实际指导意义不大，也无法基于真实数据环境进行推演。该消防救援大队部署中科云腾灭火救援三维数字化预案系统，为辖区内重点单位构建三维仿真模型，并在模型上标注消防设施位置，录入大队装备、人力资源等数据，形成高度仿真的电子沙盘，队站指挥员与消防员可基于电子沙盘进行重点单位灭火救援过程推演，模拟灾情发生、企业处置、力量调派与力量部署等环节，真正做到战训一体，无缝对接，为辖区重点单位火灾事故处置增加了胜利的筹码。'
  }
]
