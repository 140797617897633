<template>
  <div>
    <banner :title="currentTopContent[0]" :content="currentTopContent[1]" :background="currentTopContent[2]" />

    <transition name="page" mode="out-in" enter-active-class="animate__animated animate__fadeIn">
      <router-view />
    </transition>

    <product-footer v-if="currentFooterContent" :title="currentFooterContent.title" :content="currentFooterContent.content" :img="currentFooterContent.img"></product-footer>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import ProductFooter from './components/Footer'
import { topContents, footerContents } from './conf'

export default {
  name: 'Products',

  components: {
    Banner,
    ProductFooter
  },

  data() {
    return {
      currentTopContent: [],
      currentFooterContent: {}
    }
  },

  watch: {
    '$route.name': {
      immediate: true,
      handler(val, oldVal) {
        if (!val || val === oldVal) return

        this.currentTopContent = topContents[val]
        this.currentFooterContent = footerContents[val]
      }
    }
  }
}
</script>

<style lang="scss"></style>
