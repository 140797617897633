<template>
  <div class="banner-center">
    <div class="banner-center_angle">
      <span class="banner-center_angle_left-top"></span>
      <span class="banner-center_angle_right-top"></span>
      <span class="banner-center_angle_left-bottom"></span>
      <span class="banner-center_angle_right-bottom"></span>
    </div>

    <h2>中科云腾</h2>
    <h2>用科技捍卫城市安全</h2>
    <div class="banner-center_line"></div>
    <p class="banner-center_p">基于物联网、大数据、AI和VR等先进技术，打造一站式应急产品解决方案，为城市应急赋能。</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.banner-center {
  @include size(616px, 313px);
  @include flex-center(center, column);
  padding: 30px;
  box-sizing: border-box;
  line-height: 1.5;
  color: #fff;
  background: rgba(0, 8, 53, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background 0.2s ease;

  &:hover {
    background: rgba(0, 8, 53, 0.8);
  }

  [class*='_angle_'] {
    width: 10px;
    height: 10px;
    position: absolute;
    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: #00eaff;
    }
    &::before {
      width: 10px;
      height: 2px;
    }
    &::after {
      width: 2px;
      height: 10px;
    }
  }

  &_angle {
    &_left-top,
    &_left-top::before,
    &_left-top::after {
      top: 0;
      left: 0;
    }
    &_right-top,
    &_right-top::before,
    &_right-top::after {
      top: 0;
      right: 0;
    }
    &_left-bottom,
    &_left-bottom::before,
    &_left-bottom::after {
      left: 0;
      bottom: 0;
    }
    &_right-bottom,
    &_right-bottom::before,
    &_right-bottom::after {
      right: 0;
      bottom: 0;
    }
  }

  &_line {
    width: 483px;
    height: 2px;
    background: rgba(0, 8, 53, 0.6);
    position: relative;
    margin-top: 5px;

    &::before {
      content: '';
      width: 200px;
      height: 100%;
      background-color: #00fff9;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &_p {
    font-size: 16px;
    margin-top: 17px;
  }

  @media screen and (max-width: 768px) {
    width: 298px;
    height: 180px;
    padding: 15px;

    font-size: 9px;

    h2 {
      font-size: 19px;
    }

    &_line {
      width: 236px;
      &:before {
        width: 100px;
      }
    }

    &_p {
      font-size: 9px;
    }
  }
}
</style>
