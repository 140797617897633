import home from '@/assets/images/home.png'
import world from '@/assets/images/world.png'
import fire from '@/assets/images/fire.png'

export default [
  {
    label: '首页',
    key: 'home'
  },
  {
    label: '产品与服务',
    key: 'products',
    children: [
      {
        title: '城市服务应急',
        icon: home,
        subTitles: [{ title: '城市应急安全数据中台', name: 'Safe' }]
      },
      {
        title: '服务消防救援队',
        icon: world,
        subTitles: [
          { title: '装备实战管理平台', name: 'Device' },
          {
            title: '灭火救援数字化预案管理平台',
            name: 'Fireplan'
          },
          { title: '119互联网辅助接处警系统', name: 'Alarm' }
        ]
      },
      {
        title: '服务社会消防单位',
        icon: fire,
        subTitles: [
          {
            title: '社会单位数字化应急预案管理平台',
            name: 'Society'
          },
          {
            title: '社会单位智慧消防物联网监控云平台',
            name: 'Monitor'
          }
        ]
      }
    ],
    children2: [
      { title: '城市应急安全数据中台', name: 'Safe' },
      { title: '装备实战管理平台', name: 'Device' },
      { title: '灭火救援数字化预案管理平台', name: 'Fireplan' },
      { title: '119互联网辅助接处警系统', name: 'Alarm' },
      { title: '社会单位数字化应急预案管理平台', name: 'Society' },
      { title: '社会单位智慧消防物联网监控云平台', name: 'Monitor' }
    ]
  },
  {
    label: '媒体中心',
    key: 'media'
  },
  {
    label: '应用案例',
    key: 'talentplan'
  },
  {
    label: '关于我们',
    key: 'about'
  }
]
