<template>
  <div class="talent-plan-detail">
    <div class="talent-plan-detail_route">
      <!-- eslint-disable -->
      <span @click="goBack"> < &nbsp;</span>
      <!-- eslint-enable -->
      <span @click="goBack">应用案例</span>
    </div>

    <div class="talent-plan-detail_main">
      <!-- 正文 -->
      <div class="talent-plan-detail_main_content">
        <h2>{{ talentPlanData.title }}</h2>

        <div class="talent-plan-detail_content">
          <img :src="talentPlanData.avatar" :alt="talentPlanData.title" />
          <p>{{ talentPlanData.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listsData } from './data'

export default {
  data() {
    return {
      talentPlanData: {},
      randomData: []
    }
  },

  created() {
    this.talentPlanData = listsData.find(item => +item.id === +this.$route.params.id)
  },

  methods: {
    goBack() {
      this.$router.replace({ path: '/talentPlan' })
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/mixins';

.talent-plan-detail_route {
  width: 100%;
  @include flex-center(flex-start);
  padding: 23px 120px;
  background-color: #fff;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
  box-sizing: border-box;
  span {
    margin-right: 10px;
    cursor: pointer;
    &:first-child {
      color: #005eef;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 12px 20px;
  }
}

.talent-plan-detail_main {
  @include size(100%);
  @include flex-center(space-between);
  align-items: flex-start;
  padding: 0 119px;
  background-color: #fff;
  color: rgba($color: #000000, $alpha: 0.8);
  box-sizing: border-box;

  .talent-plan-detail_main_content {
    font-size: 16px;
    flex-grow: 2;
    margin-right: 50px;

    h2 {
      font-size: 32px;
      margin-top: 26px;
    }

    .talent-plan-detail_content {
      margin-top: 23px;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 19px;
      }

      p {
        color: #666;
        margin-bottom: 30px;
        line-height: 1.5;
        text-indent: 2em;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    flex-wrap: wrap;

    .talent-plan-detail_main_content {
      font-size: 12px;
      margin-right: 0;
      h2 {
        font-size: 14px;
        margin-top: 16px;
      }

      & > p {
        border-bottom: none;
      }

      .talent-plan-detail_content {
        margin-top: 0;
      }
    }
  }
}
</style>
