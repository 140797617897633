<template>
  <div id="app scroll-none all-hide">
    <Menu></Menu>

    <transition name="page" mode="out-in" enter-active-class="animate__animated animate__fadeIn">
      <router-view />
    </transition>

    <Footer></Footer>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Menu,
    Footer
  }
}
</script>

<style lang="scss">
#app {
  font-size: 16px;
  box-sizing: border-box;
  font-family: PingFangSC-Regular, PingFang SC;
  overflow-x: hidden;
}

p {
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  /*webkit内核*/
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
}

/*o内核*/
::-o-scrollbar {
  -moz-appearance: none !important;
  width: 0px;
  height: 0px;
}

.hvr-bounce-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover,
.hvr-bounce-in:focus,
.hvr-bounce-in:active {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

.hvr-float {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-float:hover,
.hvr-float:focus,
.hvr-float:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
</style>
