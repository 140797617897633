<template>
  <div class="fire-container">
    <introduce title="消防灭火救援数字化预案功能体系" :lists="lists"></introduce>

    <shuffl title="消防基础数据治理—数据采集" :lists="swiperLists"></shuffl>

    <div class="deduction-production">
      <h2>三维数字化预案推演与制作</h2>

      <div>
        <div v-for="(item, index) in swiperLists2" :key="index">
          <img :src="item.img" :alt="item.img" />
          <h4>{{ item.title }}</h4>
          <p v-for="sub in item.subTitles" :key="sub">{{ sub }}</p>
        </div>
      </div>
    </div>

    <shuffl class="deduction-production_shuffl" title="三维数字化预案推演与制作" :lists="swiperLists2"></shuffl>
  </div>
</template>

<script>
import Introduce from './components/Introduce'
import Shuffl from './components/Shuffl'

function getImg(index) {
  return require('@/assets/images/product/fire/' + index + '.png')
}

export default {
  name: 'fire',
  components: {
    Shuffl,
    Introduce
  },

  data() {
    return {
      lists: [
        ['消防基础数据治理', '消防基础数据的采集、清洗、标注、分析与稽核', '移动端现场数据采集与校验（六熟悉工具）'],
        ['三维数字化预案推演与制作', '假定火灾场景，基于消防基础数据，遵循灭火救援的基本流程，运用一系列三维可视化工具模型设计灭火救援过程，推算灾情处置策略 '],
        ['预案管理与决策', '实现预案的提交、审核和存档，协助消防主管单位建立预案大数据监控体系，评估辖区火灾风险']
      ],

      swiperLists: [
        {
          img: 'https://socialize.zkyunteng.cn/fronts/website/product/data-indicate.png',
          title: '消防力量基础数据库',
          subTitles: ['包含消防队站人力资源、装备力量（车辆、器材、泡沫药剂）、队站辖区范围、辖区水源、辖区消防栓、联动力量、专家库、灭火救援知识库等基础数据的录入与校验']
        }
      ],

      swiperLists2: [
        {
          title: '灾情假定',
          img: getImg(0),
          subTitles: ['灾情假定可在三维场景点击任意位置产生指定类型的火灾事故，并可设定相应的气候条件']
        },
        {
          title: '企业处置',
          img: getImg(1),
          subTitles: ['企业处置可设置火灾事故发生时人员疏散、工艺处置和先期消防设施启用流程']
        },
        {
          title: '力量调派',
          img: getImg(2),
          subTitles: ['力量调派设定参站的人员、车辆、器材和药剂，并可基于地图自动生成导航路线（计算距离和到达时间），指定集结区域']
        },
        {
          title: '力量部署',
          img: getImg(3),
          subTitles: ['力量部署提供危险性分析、供水量与泡沫药剂需求计算、站位标绘，供水任务标绘、进攻路线标绘、撤退路线标绘等任务设置']
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.fire-container {
  color: rgba($color: #000000, $alpha: 0.8);

  h3 {
    font-size: 32px;
    text-align: center;
  }

  p {
    line-height: 1.5;
  }
}

.slide-item {
  padding: 80px 30px 76px;
  box-sizing: border-box;
  background-color: #fff;

  & > div {
    margin-top: 69px;
    @include flex-center(center);
    align-items: flex-start;
    img {
      @include size(541px, 327px);
      margin-right: 40px;
    }
    div {
      width: 321px;
      @include flex-center(center, column);
      align-items: flex-start;

      h4 {
        font-size: 28px;
        margin-top: 20px;
      }

      span {
        @include size(100%, 2px);
        background-color: #f0f0f0;

        margin: 20px 0 26px;
        position: relative;

        &:before {
          content: '';
          @include size(120px, 2px);
          background-color: #005eef;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.deduction-production {
  padding: 80px 30px 129px;
  background-color: #f8fafc;
  text-align: center;

  h2 {
    font-size: 32px;
  }

  & > div {
    @include flex-center(center);
    align-items: flex-start;
    text-align: left;
    flex-wrap: wrap;
    margin-top: 27px;

    & > div {
      @include size(282px, 312px);
      box-shadow: 2px 2px 8px 0px rgba(192, 192, 192, 0.4);
      margin-right: 24px;
      margin-top: 20px;
      overflow: hidden;
      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    img {
      @include size(282px, 160px);
      transition: transform 0.2s ease;
    }

    h4 {
      margin-top: 23px;
      margin-left: 22px;
      margin-right: 22px;
      font-size: 16px;
    }
    p {
      margin-top: 12px;
      margin-left: 22px;
      margin-right: 22px;
      font-size: 14px;
      color: rgba($color: #000000, $alpha: 0.6);
    }
  }
}

.deduction-production_shuffl {
  display: none;
  padding-top: 20px !important;

  /deep/ .swiper-slide > div {
    img {
      height: 160px !important;
      margin-right: 0;
      vertical-align: top;
    }
  }
}

@media screen and (max-width: 768px) {
  .deduction-production {
    display: none;
  }

  .deduction-production_shuffl {
    display: flex;
  }
}
</style>
