<template>
  <div class="product-system" v-if="lists.length">
    <div class="product-system-contain">
      <div class="product-system-contain-header">
        <h2>{{ title }}</h2>
        <p>{{ subTitle }}</p>
      </div>
      <div class="product-system-contain-content">
        <div
          class="product-system-background"
          :style="{
            'background-image': 'url(' + lists[currentIndex].img + ')'
          }"
        ></div>
        <div class="menuTab">
          <div>
            <h3>{{ lists[currentIndex].title }}</h3>
            <h5>{{ lists[currentIndex].subTitle }}</h5>
            <ul>
              <li v-for="(item, index) in lists[currentIndex].descList" :key="index">{{ item }}</li>
            </ul>
          </div>

          <div class="my-pagination">
            <span
              v-for="(item, index) in lists"
              :key="index"
              :class="{
                'my-pagination-bullet': true,
                'my-pagination-bullet-active': currentIndex === index
              }"
              @click="tabBullet(index)"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const DURATION = 3500

export default {
  props: {
    title: String,
    subTitle: String,
    lists: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      currentIndex: 0
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.timeOutBullet()
    })
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },

  methods: {
    tabBullet(index) {
      this.currentIndex = index
      this.timeOutBullet()
    },

    timeOutBullet() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }

      this.timer = setInterval(() => {
        if (this.currentIndex < this.lists.length - 1) {
          this.currentIndex += 1
        } else {
          this.currentIndex = 0
        }
      }, DURATION)
    }
  }
}
</script>

<style lang="scss" scoped>
// 轮播动画
@keyframes pagination-bullet {
  from {
    right: 0;
  }
  to {
    right: -149px;
  }
}

.my-pagination {
  display: flex;
  margin-top: 11.03%;
  &-bullet-active {
    background: #fff !important;
    &:not(:last-child) {
      &::after {
        right: 0;
        animation: pagination-bullet 3.5s ease;
        animation-fill-mode: forwards;
        background: #fff !important;
      }
    }
  }
  &-bullet {
    border-radius: unset;
    width: 8px;
    height: 18px;
    background: rgba(255, 255, 255, 0.23);
    transform: skew(-10deg);
    margin: 0 149px 0 0;
    position: relative;
    cursor: pointer;
    display: inline-block;
    &:last-child {
      &::before {
        content: unset;
      }
    }
    &::after {
      background-color: #fff;
      right: 0;
    }
    &::before {
      right: -149px;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 8px;
      height: 2px;
      top: 8px;
      transform: skew(-10deg);
      background: rgba(255, 255, 255, 0.23);
    }
  }
}

.product-system {
  width: 100%;
  max-height: 744px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  padding: 70px 0;
  box-sizing: border-box;
  text-align: center;
  &-contain {
    width: 971px;
    &-content {
      width: 100%;
      height: 399px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      background: #001c46;
      margin-top: 67px;
      .product-system-background {
        flex: 0 0 600px;
        // width: 600px;
        height: 399px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
      .menuTab {
        padding: 24px;
        & > div:first-child {
          width: 334px;
          box-sizing: border-box;
        }
        ul {
          margin-top: 36px;
          height: 144px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          li {
            width: 100%;
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.8);
            word-break: normal;
            margin-bottom: 16px;
          }
        }
        h3 {
          font-size: 28px;
          font-weight: 600;
          color: rgba(255, 255, 255, 0.95);
          text-align: left;
          margin-bottom: 11px;
        }
        h5 {
          font-size: 18px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.8);
          text-align: left;
        }
      }
    }
    h2 {
      font-size: 32px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 43px;
    }
    p {
      text-align: left;
      text-indent: 2em;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
    }
    &-header {
      width: 100%;
    }
  }
}
</style>
