<template>
  <div class="about">
    <div class="banner">
      <div class="banner-content">
        <h3>企业简介</h3>
        <p>
          中科云腾专注于为应急消防救援领域提供高效、智能的产品解决方案。
          <br />通过构造城市数字孪生环境、整合消防安全大数据，应用先进人工智能算法，中科云腾智慧消防平台可科学评估社会单位火灾风险，自动生成救援方案，实现社会单位与消防救援队伍之间的数据联动，从而达到“精准防控、智慧应急”的目标。中科云腾在城市应急数字化预案平台和城市消防救援智能指挥系统方面，拥有完整的自主知识产权和系列核心技术。
          <br />当前中科云腾正与多个知名企业强强联合，共同建设“智慧应急”生态，推动行业规范化、规模化和产业化的进程。
        </p>
      </div>
      <ul class="mission-txt" v-if="enterpriseMission">
        <li>
          <p>企业精神</p>
          <p>专注城市安全科技创新，用心打造应急救援利器，坚守使命，敢于创新！</p>
        </li>
        <li>
          <p>企业愿景</p>
          <p>做中国最大的城市应急救援信息服务商</p>
        </li>
        <li>
          <p>核心价值观</p>
          <p>专心 &nbsp;&nbsp; 专注&nbsp;&nbsp; 奋斗&nbsp;&nbsp; 担当</p>
        </li>
      </ul>
    </div>

    <div class="about-content">
      <h2>战略投资股东</h2>
      <div class="about-content_item wrap">
        <img v-for="item in [1, 2, 3]" :key="item" :src="require('@/assets/images/about/company0' + item + '.png')" alt />
      </div>
      <div class="about-content_item wrap">
        <img v-for="item in [4, 5, 6]" :key="item" :src="require('@/assets/images/about/company0' + item + '.png')" alt />
      </div>

      <div id="about-content_swiper_2" class="about-content_swiper swiper-container about-content_swiper_wrap">
        <div class="swiper-wrapper">
          <img class="swiper-slide" v-for="item in [1, 2, 3]" :key="item" :src="require('@/assets/images/about/company0' + item + '.png')" alt />
        </div>
      </div>
      <div id="about-content_swiper_3" class="about-content_swiper swiper-container about-content_swiper_wrap">
        <div class="swiper-wrapper">
          <img class="swiper-slide" v-for="item in [4, 5, 6]" :key="item" :src="require('@/assets/images/about/company0' + item + '.png')" alt />
        </div>
      </div>

      <h2>企业荣誉</h2>
      <p>
        广州中科云腾科技有限公司作为应急救援行业信息化与智能化的领导者，与华南地区多个消防救援支队、总队有着密切的合作关系，先后获批成为华南地区城市应急救援安全大数据中心和应急消防数据化预案研究中心，并成为北斗生态圈在应急消防领域产业研发及应用示范基地。
      </p>
      <div class="about-content_item">
        <img v-for="item in [0, 1, 2]" :key="item" :src="require('@/assets/images/about/' + item + '.png')" alt />
      </div>

      <div id="about-content_swiper_0" class="about-content_swiper swiper-container">
        <div class="swiper-wrapper">
          <img class="swiper-slide" v-for="item in [0, 1, 2]" :key="item" :src="require('@/assets/images/about/' + item + '.png')" alt />
        </div>
      </div>

      <!-- <h2>企业资质</h2>
      <div class="about-content_item about-content_item_1">
        <img
          v-for="item in [3, 4, 5]"
          :key="item"
          :src="require('@/assets/images/about/' + item + '.png')"
          alt
        />
      </div>

      <div id="about-content_swiper_1" class="about-content_swiper swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in [3, 4, 5]" :key="item">
            <img :src="require('@/assets/images/about/' + item + '.png')" alt />
          </div>
        </div>
      </div>-->

      <swiper-list title="企业资质" :lists="shuffleLists"></swiper-list>
    </div>

    <div class="contact-us">
      <h2>联系我们</h2>

      <div>
        <div>
          <h2>广州</h2>
          <p>020-85165485</p>
          <p>zkyt@zkyunteng.com</p>
          <p>{{ LOCATION_NAME }}</p>
        </div>

        <div id="map-container"></div>

        <div>
          <p>广州 020-85165485</p>
          <p>{{ LOCATION_NAME }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BMapGL from 'BMapGL'

import Swiper, { Navigation, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

// swiper停留时间
const SWIPER_DELAY = 6000
// 动画过渡时间
const SWIPER_SPEED = 1000

const LOCATION = [113.442478, 23.168394]
const LOCATION_NAME = '广州市开发区彩频路 7号广东软件科学园 D 栋 3 楼'
import SwiperList from './components/SwiperList'

export default {
  components: {
    SwiperList
  },

  data() {
    return {
      LOCATION_NAME,
      enterpriseMission: false,
      shuffleLists: [
        {
          imgList: [3, 4, 5]
        },
        {
          imgList: [6, 7, 8]
        },
        {
          imgList: [9]
        }
      ]
    }
  },

  created() {
    this.top = 0
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.enterpriseMission = true
      }, 1000)

      this.initSwiper()
      this.initMap()
    })
  },

  methods: {
    initSwiper() {
      Swiper.use([Navigation, Autoplay])
      ;[0, 1, 2, 3].forEach((item) => {
        new Swiper(`#about-content_swiper_${item}`, {
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          autoplay: {
            delay: SWIPER_DELAY,
            disableOnInteraction: false
          },
          speed: SWIPER_SPEED
        })
      })
    },

    initMap() {
      const map = new BMapGL.Map('map-container')
      const point = new BMapGL.Point(...LOCATION)

      map.centerAndZoom(point, 19)
      map.enableScrollWheelZoom()

      const marker = new BMapGL.Marker(point)

      const infoWindow = new BMapGL.InfoWindow(`地址：${LOCATION_NAME}`, {
        width: 200,
        height: 100,
        title: '中科云腾',
        message: '中科云腾'
      })

      marker.addEventListener('click', () => {
        map.openInfoWindow(infoWindow, point) //开启信息窗口
      })

      map.addOverlay(marker)
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/mixins';

@keyframes showUl {
  from {
    opacity: 0;
    bottom: 0;
  }
  to {
    opacity: 1;
    bottom: 60px;
  }
}
.about {
  background-color: #f0f2f5;
  .banner {
    width: 100%;
    height: 100vh;
    background: url('https://socialize.zkyunteng.cn/fronts/website/about.jpg') no-repeat center center;
    background-size: cover;
    z-index: 1;
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.62);
      filter: blur(2px);
      z-index: 2;
    }
    .mission-txt {
      width: 100%;
      padding: 0 121px;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 60px;
      z-index: 11;
      @include flex-center(center);
      animation: showUl 2s ease;
      li {
        width: 384px;
        height: 152px;
        background: rgba(1, 46, 101, 0.5);
        margin-right: 24px;
        color: rgba($color: #fff, $alpha: 0.8);
        padding: 24px;
        box-sizing: border-box;
        text-align: center;
        line-height: 1.5;
        @include flex-center(center, column);
        &:hover {
          margin-top: -20px;
          p:first-child {
            &::before {
              top: 0;
              opacity: 1;
            }
            &::after {
              top: 0;
              opacity: 1;
            }
          }
        }
        p:first-child {
          font-size: 18px;
          color: #fff;
          position: relative;
          margin-bottom: 12px;
          &::before,
          &::after {
            content: '';
            position: absolute;
            width: 6px;
            height: 17px;
            transform: skew(-10deg);
            background-color: #2590f2;
            opacity: 0;
            transition: all 0.3s linear;
          }
          &::before {
            top: -10px;
            left: -14px;
          }
          &::after {
            top: 10px;
            right: -14px;
          }
        }
        &:last-child {
          margin-right: unset;
        }
      }
    }
    .banner-content {
      position: absolute;
      left: 0;
      right: 0;
      // bottom: 334px;
      margin: 0 auto;
      z-index: 11;
      width: 42%;
      text-align: center;

      top: 50%;
      transform: translateY(-50%);
      h3 {
        font-size: 32px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.95);
      }
      p {
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        line-height: 30px;
        margin-top: 23px;
      }
    }

    @media screen and (max-width: 768px) {
      .mission-txt {
        padding: 0 9px;
        bottom: 30px;
        li {
          // width: 119px;
          width: 30%;
          height: 130px;
          margin-right: 0;
          font-size: 10px;
          padding: 15px 6px 10px;
          color: rgba($color: #fff, $alpha: 0.8);
          justify-content: flex-start;
          p:first-child {
            font-size: 12px;
            margin-bottom: 6px;
            &::before,
            &::after {
              width: 4px;
              height: 9.5px;
              top: 3px;
              opacity: 1;
            }
          }
        }
      }

      .banner-content {
        width: 80%;
        // bottom: 184px;
        h3 {
          font-size: 22px;
        }

        p {
          font-size: 12px;
        }
      }
    }
  }

  &-content {
    padding: 0 120px 90px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.5;
    color: rgba($color: #000000, $alpha: 0.8);
    overflow-x: scroll;
    @include flex-center(center, column);

    h2 {
      font-size: 32px;
      margin-top: 70px;
      margin-bottom: 19px;
    }
    p {
      max-width: 950px;
      text-indent: 2em;
    }

    &_item {
      @include flex-center(center);
      margin-top: 44px;
      img {
        margin-right: 24px;
        @include size(384px, 244px);

        &:last-child {
          margin-right: 0;
        }
      }
      &_1 {
        img:nth-child(1) {
          @include size(542px, 406px);
        }
        img {
          @include size(307px, 410px);
        }
      }

      &.wrap {
        img {
          margin: 0 24px 24px 0;
          @include size(384px, 110px);
          max-width: 384px;
        }
        &:nth-child(2) {
          margin-top: 10px;
        }

        &:nth-child(3) {
          margin-top: 0;
          img {
            margin-bottom: 0;
          }
        }
      }
    }

    &_swiper {
      display: none;
      @include size(100%, 200px);
      margin-top: 20px;
      .swiper-slide {
        @include size(299px, 190px);
        margin-right: 12px;
        background-color: #f0f2f5;
      }
      img {
        @include size(100%);
      }
      &:last-of-type {
        @include size(100%, 200px);
        .swiper-slide:nth-child(1) {
          @include size(270px, 200px);
        }
        .swiper-slide {
          margin-right: 12px;
          @include size(148px, 200px);
        }
      }

      &_wrap {
        @include size(100%, 110px);
        .swiper-slide {
          @include size(299px, 110px);
        }
        &:last-of-type {
          @include size(100%, 110px);
          .swiper-slide:nth-child(1) {
            @include size(299px, 110px);
          }
          .swiper-slide {
            margin-right: 12px;
            @include size(299px, 110px);
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0 24px;
      font-size: 12px;

      h2 {
        font-size: 20px;
        margin-top: 35px;
        margin-bottom: 15px;
      }

      & > p {
        max-width: 100%;
      }

      &_item {
        display: none !important;
      }

      &_swiper {
        display: block;
      }
    }
  }
}

.contact-us {
  padding: 0 30px 67px;
  box-sizing: border-box;
  @include flex-center(center, column);
  color: rgba($color: #000000, $alpha: 0.8);
  background-color: #fafafa;

  & > h2 {
    font-size: 32px;
    margin: 30px 0;
  }

  & > div {
    padding: 24px;
    box-sizing: border-box;
    @include flex-center();
    align-items: flex-start;
    background-color: #fff;

    & > div:not(#map-container) {
      font-size: 18px;
      margin-right: 53px;

      h2 {
        padding-left: 16px;
        position: relative;
        margin-bottom: 27px;
        font-size: 32px;

        &::before {
          content: '';
          @include size(6px, 36px);
          background-color: #005eef;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -18px;
        }
      }
      p {
        margin-bottom: 15px;
      }
    }

    div:last-child {
      display: none;
      p {
        margin-top: 6px;
        font-size: 12px;
        margin-bottom: 0 !important;
        margin-top: 4px;
        &:first-child {
          font-size: 16px;
          font-weight: bold;
          margin-top: 8px;
        }
      }
    }
  }

  #map-container {
    @include size(790px, 472px);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 26px 35px;
    background-color: #f0f2f5;

    & > h2 {
      font-size: 20px;
      margin-bottom: 24px;
    }

    & > div {
      width: 100%;
      padding: 7px 7px 18px;
      flex-direction: column;
      box-shadow: 0px 2px 16px 3px rgba(91, 91, 91, 0.15);

      div:first-child {
        display: none;
      }
      div:last-child {
        display: block;
      }
    }

    #map-container {
      @include size(100%, 187px);
    }
  }
}
</style>
