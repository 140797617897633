<template>
  <div
    id="banner"
    class="banner"
    :style="{
      backgroundImage: 'url(' + background + ')'
    }"
  >
    <div
      :class="{
        'banner-content': true,
        'banner-content_center': center
      }"
    >
      <h3>{{ title }}</h3>
      <p v-if="content">{{ content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,

    content: {
      type: [String, Array],
      default: ''
    },

    center: Boolean,

    background: String
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

@keyframes titleTxtShow {
  from {
    transform: translateY(300px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes contentTxtShow {
  from {
    transform: translateY(300px);
  }
  to {
    transform: translateY(20px);
  }
}

.banner {
  @include size(100%, 480px);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  position: relative;
  &-content {
    width: 626px;
    text-align: left;
    margin-top: 164px;
    margin-left: 206px;
    position: relative;
    z-index: 3;
    p {
      color: rgba(255, 255, 255, 0.8);
      width: 601px;
      line-height: 30px;
      animation: contentTxtShow 1.5s ease;
      animation-fill-mode: forwards;
    }

    &_center {
      text-align: center;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  h3 {
    width: 100%;
    font-size: 32px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.95);
    animation: titleTxtShow 1s ease;
    animation-fill-mode: forwards;
  }
  &::before {
    content: '';
    @include size(100%);
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.62);
    filter: blur(2px);
    z-index: 2;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    height: 300px;
    @include flex-center(center, column);

    h3 {
      font-size: 22px;
    }

    &-content {
      width: 100%;
      margin: 0;
      text-align: center;
      @include flex-center(center, column);
      margin-top: 20px;
      p {
        width: 80%;
        line-height: 1.5;
        text-align: justify;
        text-indent: 2em;
      }
    }
  }
}
</style>
