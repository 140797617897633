function getImg(index) {
  return `https://socialize.zkyunteng.cn/fronts/website/media/media-${index}.png`
}

export default [
  {
    title: '中科云腾受邀参加长炼石化战评复盘及演练 ',
    time: '2020-04-15',
    logo: getImg(2),
    id: 1,
    data: [
      {
        type: 1,
        content:
          '为严格落实“每战必评”工作要求，及时深入复盘战例，总结实战经验、解决灭火救援中存在问题和难题，不断提升队伍战斗力，寻找打赢致胜的法宝，更好保护人民群众生命财产安全，4月，珠海特勤大队在长炼石化进行了战评复盘及演练。中科云腾公司派代表随行，进行学习调研。'
      },
      {
        type: 1,
        content:
          '珠海长炼石化设备有限公司是深圳埃森新能源集团所属全资公司，位于珠海高栏港经济石化区北侧，建于2003年8月。主要生产装置有120个/年预先装置，100催化剂/年催化重整装置，40台/年芳烃抽提精馏装置，80台/年后分馏以及20台/年苯和甲苯加氢装置等相关的公用工程和储运系统。1月14日13时40分，珠海长炼石化设备有限公司重整与加氢装置预加氢单元发生闪爆。事件发生后，珠海市、区应急管理、消防、生态环境、公安、安监等部门赶赴现场，组织全力扑救。珠海市、高栏港区及时启动了应急预案，各项应急处置工作有序进行。处理工作开展迅速，明火被及时扑灭，灾情得到了迅速有效的控制。'
      },
      {
        type: 1,
        content: '复盘过程中，通过现场学习了解，中科云腾公司进一步提炼了经验做法，深入剖析作战环节存在的问题，分析问题成因，提出了改进措施。'
      }
    ]
  },
  {
    title: '我司代表应邀参加2020广州互联网＋新经济峰会',
    time: '2020-08-26',
    logo: getImg(11),
    id: 5,
    data: [
      {
        type: 1,
        content: '由广州互联网协会组织的 2020年广州互联网+新经济峰会暨首届粤港澳大湾区数字经济论坛 在广州四季酒店圆满举行。'
      },
      {
        type: 1,
        content:
          '作为广州市乃至广东省互联网领域规格最高的行业峰会，会议当天吸引参会人数超过千人。我司与网易、唯品会、虎牙、酷狗等知名互联网企业代表和广州互联网垂直细分领域的20强企业负责人共同出席参加了本次峰会。'
      },
      {
        type: 1,
        content:
          '此次广州互联网+新经济峰会正是瞄准新经济下的新增长点，重点关注互联网下半场引发的商业变革，重新审视互联网未来的发展方向，就5G、互联网、区块链、大数据、人工智能、数字创意经济等方面，系统剖析未来互联网+下的新经济、新业态、新模式，深度解析互联网企业如何站稳脚跟、转型升维。'
      },
      {
        type: 1,
        content: '会上举行了广州互联网协会人工智能与大数据应用专委会聘书颁发仪式，我司代表应邀受聘为专委会委员。'
      }
    ]
  },
  {
    title: '中科云腾打造的物联网装备管理系统获消防部门的高度肯定',
    time: '2020-06-18',
    logo: getImg(12),
    id: 6,
    data: [
      {
        type: 1,
        content: '近期，由中科云腾打造的物联网装备管理系统相继在中山高新区消防大队、中山市公安消防局通过了项目招标，采购单位对中科云腾的产品和技术实力给予了充分的肯定和高度的赞扬。'
      },
      {
        type: 1,
        content: '该系统是应急管理部着力推动的核心业务系统，其推广使用将显著提升消防部门的仓库管理、指挥调度和资源调度能力，具有防范化解重大安全风险能力，并进一步增强智慧消防管理信息化水平。'
      },
      {
        type: 1,
        content: '该系统的顺利中标不仅是公司为消防部门交出的一份满意答卷，也体现了公司多年来在应急消防救援行业的扎实积累和深耕细作。'
      },
      {
        type: 1,
        content:
          '公司作为早期从事应急消防救援领域的公司之一，推动了国家智慧消防体系的建设和推广。 随着社会对公共安全的日益重视，公司紧紧抓住了智慧消防与应急救援行业快速发展的机遇，利用自身对行业理解、专业深度、技术储备、市场资源等方面的壁垒优势，牢牢占据行业领先地位。未来公司将继续扎实打造应急消防救援领域核心竞争力，强化品牌影响力，持续加强市场拓展能力。'
      }
    ]
  },
  {
    title: '中科云腾连续中标多个项目',
    time: '2021-01-10',
    logo: getImg(13),
    id: 7,
    data: [
      {
        type: 1,
        content:
          '新年之际，中科云腾在智慧消防的推广和建设上颇有斩获，包括江门市消防救援支队消防装备实战管理及战勤保障平台设备采购项目、珠海市横琴新区消防救援大队三维建模项目、中山市公安消防局装备物联网等多个项目陆续中标。公司基于自身在智慧消防领域的技术优势，通过物联网、云计算、大数据、人工智能等现代信息技术赋能传统消防产业，并进一步推广创新的消防安全服务模式。'
      },
      {
        type: 1,
        content: '未来，公司将继续沿着发展安全科技的道路不断前进，持续聚焦智慧消防产业，赋能消防应急救援事业新发展。'
      }
    ]
  }
]
