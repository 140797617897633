<template>
  <div :class="['banner-left', 'banner-left-' + mode]">
    <div>
      <template v-if="mode === 2">
        <h2>构建城市应急消防数据中台</h2>
        <h2>提升城市应急响应能力</h2>
        <div class="banner-left_line"></div>
        <p class="content">全面汇聚城市应急基础数据，训练救援策略机器学习模型，为城市应急消防提供统一的数据视图和智慧大脑</p>
      </template>

      <template v-if="mode === 3">
        <h2>提升消防救援效率</h2>
        <h2>保障生命财产安全</h2>
        <div class="banner-left_line_2"></div>
        <p class="content">为消防救援单位提供闭环信息化产品解决方案，实现“接警高效、调度智能、指挥科学、管理规范”的目标</p>
      </template>

      <template v-if="mode === 4">
        <h2>建设社会单位消防预案应用体系</h2>
        <h2>降低火灾事故风险</h2>
        <div class="banner-left_line_2"></div>
        <p class="content">为社会单位提供三维数字化应急消防预案管理平台，整合防火与监控，全面提升社会单位防火工作效率与火灾自救处置能力</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['mode']
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.banner-left {
  width: 50%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  @include flex-center(center, column);
  align-items: flex-end;
  padding-left: 30px;
  padding-right: 100px;
  box-sizing: border-box;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;

  & > div {
    max-width: 500px;
    text-align: left;
  }

  &-4 {
    padding-left: 30px;
    padding-right: 80px;
    & > div {
      max-width: 576px;
      text-align: left;
    }
  }

  p:first-child {
    font-size: 23px;
    & + h2 {
      margin-top: 15px;
    }
  }

  h2 {
    font-size: 36px;
    margin-top: 10px;
  }

  &_line {
    width: 100%;
    height: 2px;
    background-color: #08f2fe;
    margin: 20px 0;
  }

  &_line_2 {
    @extend .banner-left_line;
    background-color: #fff;
    margin: 25px 0;

    margin-left: 25px;

    position: relative;
    &::before {
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #fff;
      border: 4px solid #000;
      box-shadow: 0 0 0px 2px #fff;
      position: absolute;
      left: -23px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  p.content {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6;
  }

  @media screen and (max-width: 768px) {
    width: 70%;
    padding: 0 15px;

    h2 {
      font-size: 19px;
    }

    &_line_2 {
      width: 80%;
    }

    &_p {
      font-size: 9px;
    }
  }
}
</style>
