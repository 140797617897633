<template>
  <div class="monitor-container">
    <div v-for="(item, index) in ['总体业务架构', '总体拓扑图——系统组成']" :key="index">
      <h2>{{ item }}</h2>
      <img :src="require('@/assets/images/product/monitor/' + index + '.png')" :alt="item" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alarm'
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.monitor-container {
  color: rgba($color: #000000, $alpha: 0.8);

  h2 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 50px;
  }

  p {
    line-height: 1.5;
  }

  & > div {
    text-align: center;
    padding: 70px 30px 20px;
    &:nth-child(1) img {
      @include size(1200px, 551px);
    }
    &:nth-child(2) img {
      @include size(1200px, 640px);
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 20x;
      margin-bottom: 17px;
    }

    & > div {
      text-align: center;
      padding: 30px 15px 20px;
      &:nth-child(1) img {
        @include size(345px, auto);
      }
      &:nth-child(2) img {
        @include size(345px, auto);
      }
    }
  }
}
</style>
