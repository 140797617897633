<template>
  <div class="main-menu">
    <div :class="{ menu: true, 'menu-bg': true, 'is-top': isTop || clickMenuHamburg }">
      <div class="logo" @click="goHome">
        <span></span>
      </div>

      <ul class="menu-ul menu-list menu-list-hover">
        <li class="menu-ul-li" v-for="item in menuList" :key="item.key" @click="pushToPage(item.key)" :class="{ nowPath: `/${item.key}` === nowPathKey }">
          {{ item.label }}
          <div class="sub-menu" v-if="item.children && item.children.length">
            <ul>
              <li v-for="(child, subIndex) in item.children" :key="subIndex">
                <dl>
                  <dt>
                    <img :src="child.icon" />
                    {{ child.title }}
                  </dt>
                  <dd v-for="(t, i) in child.subTitles" :key="i" @click="toSubPage(t.name)">{{ t.title }}</dd>
                </dl>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <div class="menu-other">
        <span>020-85165485</span>
      </div>

      <div :class="{ 'menu-hamburg': true, active: clickMenuHamburg }" @click="clickMenuHamburg = !clickMenuHamburg">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div class="menu-hamburg_main" v-show="clickMenuHamburg">
        <ul class="menu-hamburg_main_ui">
          <li
            :class="['menu-hamburg_main_li', showMenuHamburgMain ? 'menu-hamburg_main_li-active' : '']"
            v-for="item in menuList"
            :key="item.key"
            @click="pushToPage(item.key)"
            :style="{ height: showMenuHamburgMain && item.children2 && item.children2.length ? item.children2.length * 57 + 'px' : '57px' }"
          >
            <p style="display: flex; align-items: center">
              <span>{{ item.label }}</span>
              <svg
                v-if="item.children2 && item.children2.length"
                t="1612416430391"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4079"
                width="13"
                height="13"
              >
                <path
                  d="M679.3 151.1c6.4 0 12.8 2.4 17.7 7.3 9.8 9.8 9.8 25.6 0 35.4L380.6 510.1 697 826.4c9.8 9.8 9.8 25.6 0 35.4-9.8 9.8-25.6 9.8-35.4 0l-334-334c-9.8-9.8-9.8-25.6 0-35.4l334-334c4.9-4.9 11.3-7.3 17.7-7.3z"
                  p-id="4080"
                ></path>
              </svg>
            </p>
            <ul v-if="item.children2 && item.children2.length" class="menu-hamburg_main_ui">
              <li class="menu-hamburg_main_li" v-for="child in item.children2" :key="child.name" @click="routerPush(child.name)">{{ child.title }}</li>
            </ul>
          </li>
        </ul>

        <div class="menu-hamburg_main_footer" @click="resetMenuHamburgState">
          <svg t="1612266015598" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2145" width="16" height="16">
            <path
              d="M706.56 472.448A122.88 122.88 0 0 0 611.072 327.04c-18.176-3.84-27.776-18.304-23.936-36.352 3.904-17.92 18.56-27.392 36.864-23.552 100.352 21.376 164.48 118.784 143.36 217.984-3.84 18.048-18.56 27.456-36.8 23.616-18.176-3.84-27.712-18.304-23.872-36.352v0.064z m121.472 25.472c28.352-131.84-57.664-262.656-191.232-290.752-18.176-3.776-27.776-18.304-23.936-36.288 3.904-17.984 18.624-27.52 36.8-23.68 166.976 35.072 274.56 198.656 239.104 363.456-3.84 17.92-18.56 27.392-36.8 23.552s-27.776-18.304-23.936-36.288z"
              p-id="2146"
            ></path>
            <path
              d="M380.608 350.72c21.824 60.992 47.872 116.608 81.28 167.488 33.536 50.816 74.368 96.96 121.92 141.44L646.4 619.52a91.84 91.84 0 0 1 122.688 19.456l98.432 117.76 4.8 7.296c29.312 40.512 17.088 97.472-27.072 125.824l-99.456 63.744c-95.104 58.368-281.216-93.44-441.408-336.896C144.192 373.12 77.056 139.904 172.672 78.464L272.128 14.848c0.64-3.072 3.648-2.432 7.296-4.8 46.016-21.76 102.4-3.584 124.352 41.728l68.992 136.64c22.592 42.304 8.64 92.672-31.936 118.592l-60.16 43.712z"
              p-id="2147"
            ></path>
          </svg>

          <span>020-85165485</span>

          <svg t="1612266050860" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3684" width="16" height="16">
            <path
              d="M514.112 273.256l309.44 309.44L868.8 537.44l-332.064-332.068a32 32 0 0 0-45.256 0L168 528.856l45.256 45.256z m0 320l309.44 309.44L868.8 857.44l-332.064-332.068a32 32 0 0 0-45.256 0L168 848.856l45.256 45.256z"
              p-id="3685"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuList from './menu'

export default {
  data() {
    return {
      menuList,
      nowPathKey: null,
      isTop: false,

      clickMenuHamburg: false,
      showMenuHamburgMain: false
    }
  },

  watch: {
    $route: {
      handler(val) {
        if (val.path === '/') {
          this.nowPathKey = '/home'
          return
        }
        this.nowPathKey = val.path
      },
      immediate: true,
      deep: true
    }
  },

  mounted() {
    this.$Bus.$on('change.menu.background', (state) => {
      this.disabeld = state
      this.isTop = state
    })

    this.handlerScroll = [
      'scroll',
      this.debounce(() => {
        if (this.disabeld) return
        this.isTop = window.scrollY > 0
      })
    ]
    window.addEventListener(...this.handlerScroll)
  },

  beforeDestroy() {
    window.removeEventListener(...this.handlerScroll)
  },

  methods: {
    debounce(fn) {
      let timer = null

      return function () {
        if (timer) clearTimeout(timer)

        timer = setTimeout(fn, 100)
      }
    },

    goHome() {
      this.$router.replace({ name: 'Home' })
    },

    toSubPage(name) {
      this.$router.push({ name })
    },

    pushToPage(key) {
      if (key === 'products') return this.showMenuHamburgMain = !this.showMenuHamburgMain

      this.resetMenuHamburgState()
      if (key === 'home') return this.goHome()
      this.$router.push({ name: key.toLowerCase().replace(/( |^)[a-z]/g, L => L.toUpperCase()) })
    },

    routerPush(name) {
      this.$router.push({ name })
      this.resetMenuHamburgState()
    },

    resetMenuHamburgState() {
      this.clickMenuHamburg = false
      this.showMenuHamburgMain = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.main-menu {
  width: 100%;
  height: 60px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 12;
}
.sub-menu {
  padding: 24px;
  background: #ffffff;
  position: absolute;
  left: 0;
  top: 60px;
  width: 684px;
  height: 200px;
  display: none;
  box-shadow: 2px 2px 8px 0px rgba(192, 192, 192, 0.4);
  &:hover {
    display: block;
  }
  & > ul {
    display: flex;
    li {
      margin-right: 61px;
      width: auto;
      &:last-child {
        margin: unset;
      }
      dl {
        dt {
          height: 31px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
          border-bottom: 1px solid rgba(102, 102, 102, 0.12);
          text-align: left;
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }
        }
        dd {
          height: 30px;
          line-height: 30px;
          padding-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          text-align: left;
          cursor: pointer;
          &:hover {
            color: #0e57a2;
          }
        }
      }
    }
  }
}

.menu-bg {
  transition: background 0.2s ease;
  &:hover,
  &.is-top {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.8);
    .menu-ul-li:hover {
      color: #005eef;
      .sub-menu {
        display: block;
      }
    }
    .logo span {
      background: url('~@/assets/images/logo_hover.png') no-repeat center center;
    }
    .nowPath::after {
      content: none;
    }
  }
}

.menu {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 97px;
  padding-right: 100px;
  box-sizing: border-box;
  transition: all 0.5s ease;
  color: #ffffff;

  .logo {
    flex: 0 0 96px;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      display: inline-block;
      width: 96px;
      height: 27px;
      background: url('~@/assets/images/logo.png') no-repeat center center;
      background-size: cover;
    }
  }

  .menu-ul {
    display: flex;
    width: 544px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 50px;
    .menu-ul-li {
      height: 60px;
      line-height: 60px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      flex: 1;
      position: relative;
      &:nth-child(2) {
        cursor: default;
      }
    }
    .nowPath {
      font-weight: 600;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 4px;
        left: 50%;
        bottom: 10px;
        transform: translateX(-50%);
        background-color: #fff;
      }
    }
  }
  &-list li:hover {
    color: rgba(0, 0, 0, 0.8);
  }
  &-other {
    font-size: 16px;
    margin-left: auto;
    flex-shrink: 0;
  }

  .menu-hamburg {
    @include size(20px);
    margin-top: 2px;
    margin-left: auto;
    position: relative;
    cursor: pointer;
    display: none;
    span {
      @include size(16px, 2px);
      background-color: #00378c;
      position: absolute;
      border-radius: 2px;
      transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
      transition-duration: 500ms;

      &:nth-child(1) {
        top: 4px;
        left: 0px;
      }
      &:nth-child(2) {
        top: 9px;
        left: 0px;
        opacity: 1;
      }
      &:nth-child(3) {
        bottom: 4px;
        left: 0px;
      }
    }

    &.active {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 13px;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 13px;
        }
      }
    }
  }

  .menu-hamburg_main {
    @include size(100%);
    min-height: calc(100vh - 60px);
    background-color: #f0f2f5;
    overflow-y: scroll;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 3;

    &_ui {
      padding: 0 15px;
      font-size: 14px;
      color: #323232;
      @include flex-center(center, column);
      padding-bottom: 200px;
    }
    &_li {
      transition: all 0.2s ease;
      width: 100%;
      height: 57px;
      text-align: left;
      line-height: 57px;
      border-bottom: 1px solid #d8d8d8;
      overflow: hidden;

      & > p {
        height: 57px;
      }

      svg {
        transition: transform 0.2s ease;
        transform: rotate(180deg);
        margin-left: auto;
      }

      &:active {
        background-color: #d6d7d8;
      }

      &-active {
        svg {
          transform: rotate(270deg);
        }
      }
    }

    &_footer {
      @include size(100%, 55px);
      @include flex-center();
      font-size: 14px;
      background-color: #fff;
      line-height: 55px;

      position: fixed;
      left: 0;
      bottom: 0;
      svg {
        margin-left: 17px;
        margin-right: 11px;
      }
      svg:last-of-type {
        margin-left: auto;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 10px;
    box-shadow: 0px 2px 16px 0px rgba(91, 91, 91, 0.15);
    justify-content: flex-start;
    background-color: rgba($color: #fff, $alpha: 0.8);

    .logo span {
      background: url('~@/assets/images/logo_hover.png') no-repeat center center;
    }

    .menu-ul,
    .menu-other {
      display: none;
    }

    .menu-hamburg {
      display: inline-block;
    }
  }
}
</style>
