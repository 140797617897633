<template>
  <div class="function-swiper">
    <h2>{{ title }}</h2>
    <h4 v-if="subTitle">{{ subTitle }}</h4>

    <div>
      <span v-if="lists.length > 1" class="function-swiper_pre" @click="onSlideChange('slidePrev')"></span>

      <div :id="swiperId" class="swiper-container" :style="{ paddingBottom: lists.length > 1 ? '50px' : '20px' }">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in lists" :key="item.title">
            <div>
              <p
                :style="{
                  backgroundImage: 'url(' + item.img + ')'
                }"
              ></p>
              <!-- <img :src="item.img" :alt="item.title" /> -->
              <div>
                <h4>{{ item.title }}</h4>
                <span></span>
                <p v-for="(sub, subIndex) in item.subTitles" :key="index + subIndex">
                  <template v-if="sub.isAry">
                    <!-- eslint-disable -->
                    <template v-for="s in sub.texts">
                      <strong v-if="s.isStrong">{{ s.text }}</strong>
                      <span v-else>{{ s.text }}</span>
                    </template>
                    <!-- eslint-enable -->
                  </template>
                  <template v-else>{{ sub }}</template>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="lists.length > 1" :id="swiperPaginationId" class="swiper-pagination"></div>
      </div>

      <span v-if="lists.length > 1" class="function-swiper_next" @click="onSlideChange('slideNext')"></span>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

// swiper停留时间
// const SWIPER_DELAY = 5000
// 动画过渡时间
const SWIPER_SPEED = 1000

export default {
  props: {
    title: String,

    subTitle: String,

    lists: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      swiperId: 'swiper-container_' + Math.round(Math.random() * 100),
      swiperPaginationId: 'swiper-pagination_' + Math.round(Math.random() * 100)
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.initSwiper()
    })
  },

  methods: {
    initSwiper() {
      Swiper.use([Navigation, Autoplay, Pagination])
      this.swiper = new Swiper('#' + this.swiperId, {
        pagination: {
          el: `#${this.swiperPaginationId}`
        },
        grabCursor: true,
        // autoplay: {
        //   delay: SWIPER_DELAY,
        //   disableOnInteraction: false
        // },
        loop: this.lists.length > 1,
        speed: SWIPER_SPEED
      })
    },

    onSlideChange(type) {
      this.swiper[type]()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.function-swiper {
  @include flex-center(center, column);
  color: rgba($color: #000000, $alpha: 0.8);
  position: relative;

  h2 {
    font-size: 32px;
  }

  & > h4 {
    display: none;
    width: 86.8%;
    font-size: 12px;
    line-height: 1.5;
    color: rgba($color: #000000, $alpha: 0.7);
    margin-top: 15px;
  }

  p {
    line-height: 1.5;
  }

  & > div {
    @include flex-center(center);
  }

  .swiper-container {
    width: 928px;
    margin-top: 69px;
    background-color: #fff;

    .swiper-slide > div {
      @include size(928px, 100%);
      @include flex-center(center);
      align-items: flex-start;
      background-color: #fff;

      transition: all 0.2s ease;
      & > p {
        flex-shrink: 0;
        @include size(541px, 327px);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-right: 41px;
      }

      & > div {
        margin-top: 20px;
        line-height: 1.5;
        text-align: left;

        & > span {
          display: inline-block;
          @include size(100%, 2px);
          margin: 20px 0 15px;
          background-color: #f0f0f0;
          position: relative;

          &::before {
            content: '';
            @include size(110px, 2px);
            background-color: #005eef;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        strong {
          font-size: 16px;
          color: #000;
        }

        h4 {
          font-size: 28px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      width: 100vw;
      padding-bottom: 30px;
      margin-top: 5px;

      h2 {
        font-size: 20px;
      }

      .swiper-wrapper {
        padding-top: 15px;
      }

      .swiper-slide > div {
        width: calc(100% - (23.5px * 2));
        justify-content: flex-start;
        flex-wrap: wrap;
        box-shadow: 0px 2px 16px 3px rgba(91, 91, 91, 0.15);

        padding: 10px 8px;
        margin: 0 23.5px;
        box-sizing: border-box;

        & > p {
          @include size(100%, 189.5px);
          margin-right: 0;
        }

        & > div {
          margin-top: 10px;
          font-size: 12px;

          & > span {
            display: inline-block;
            @include size(100%, 2px);
            margin: 9.5px 0 9px;
          }

          strong {
            font-size: 12px;
          }

          h4 {
            font-size: 16px;
            font-weight: bold;
          }

          p {
            padding-bottom: 8px;
          }
        }
      }
    }
  }

  &_pre,
  &_next {
    @include size(44px);
    border: 2px solid #005eef;
    border-radius: 50%;
    position: relative;
    margin-top: 60px;
    cursor: pointer;

    &::before {
      content: '';
      @include size(25px);
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12.5px;
      margin-left: -14.5px;
      background-color: #005eef;
      clip-path: polygon(38% 16%, 79% 50%, 39% 85%, 55% 50%);
    }

    &:hover {
      border-color: #032d57;

      &::before {
        background-color: #032d57;
      }
    }
  }

  &_pre {
    transform: rotate(180deg);
    margin-right: 57px;
  }

  &_next {
    margin-left: 114px;
  }

  @media screen and (max-width: 768px) {
    padding: 35px 0 0 0 !important;

    h2 {
      font-size: 20px;
    }

    h4 {
      display: block;
    }

    &_pre,
    &_next {
      display: none;
    }

    .swiper-pagination {
      display: block;
    }
  }
}

.swiper-pagination {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;

  /deep/ .swiper-pagination-bullet {
    @include size(10px);
    margin-right: 12px;
    &-active {
      background-color: #0157db;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
