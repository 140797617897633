<template>
  <div :class="['footer', content ? '' : 'footer_content']">
    <h2>{{ title }}</h2>
    <p>{{ content }}</p>

    <div class="footer-computer">
      <img src="@/assets/images/common/computer.png" alt="" />
      <img :src="img" :alt="title" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'content', 'img', 'height']
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.footer {
  @include size(100%, 665px);
  margin-bottom: 136px;

  background: url('~@/assets/images/product-features-bg.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;

  @include flex-center(flex-start, column);
  position: relative;
  box-sizing: border-box;

  h2,
  p,
  &-computer {
    position: relative;
    z-index: 3;
  }

  h2 {
    color: #fff;
    font-size: 32px;
    margin-top: 72px;
  }

  p {
    max-width: 578px;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 35px;
    line-height: 1.5;
  }

  &::before {
    content: '';
    @include size(100%);
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.62);
    filter: blur(2px);
  }

  &-computer {
    @include size(870px, 516px);
    position: absolute;
    bottom: -117px;

    img:first-child {
      @include size(870px, 516px);
    }
    img:last-child {
      @include size(650px, 365px);
      position: absolute;
      top: 69px;
      left: 50%;
      margin-left: -325px;
    }
  }

  @media screen and (max-width: 768px) {
    @include size(100%, 350px);

    h2 {
      font-size: 20px;
      margin-top: 25px;
      margin: 25px 12px 0;
      text-align: center;
    }

    p {
      width: 80%;
      margin-top: 12px;
    }

    &_content {
      height: 150px;
    }

    .footer-computer {
      bottom: -270px;
      transform: scale(0.4);
      zoom: 1;
    }
  }
}
</style>
