<template>
  <div class="society-container">
    <div>
      <h2>社会现状</h2>
      <p>城镇化推进迅速，人口快速聚集，城市生产经营活动频繁，城市空间构成越来越复杂，灾害事故（尤其是火灾事故）发生的风险增大。</p>

      <h3>火灾频发，政府防控任务重</h3>
      <ul>
        <li class="swiper_item" v-for="(item, index) in ['天津城市大厦“12·2”火灾', '宁波“11.26”江北区爆炸', '北京大兴红门冷库火灾19人死亡']" :key="index">
          <div :style="{ backgroundImage: 'url(' + 'https://socialize.zkyunteng.cn/fronts/website/product/society/0-' + index + '.png)' }"></div>
          <p>{{ item }}</p>
        </li>
      </ul>

      <div class="society-container_swiper_0 swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in ['天津城市大厦“12·2”火灾', '宁波“11.26”江北区爆炸', '北京大兴红门冷库火灾19人死亡']" :key="index">
            <div class="swiper_item">
              <div :style="{ backgroundImage: 'url(' + 'https://socialize.zkyunteng.cn/fronts/website/product/society/0-' + index + '.png)' }"></div>
              <p>{{ item }}</p>
            </div>
          </div>
        </div>
      </div>

      <h3>空间复杂，救援难度大</h3>
      <ul>
        <li class="swiper_item" v-for="(item, index) in ['“高”层建筑', '“地”下空间', '“大”跨度空间']" :key="index">
          <div :style="{ backgroundImage: 'url(' + 'https://socialize.zkyunteng.cn/fronts/website/product/society/1-' + index + '.png)' }"></div>
          <p>{{ item }}</p>
        </li>
      </ul>

      <div class="society-container_swiper_1 swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in ['“高”层建筑', '“地”下空间', '“大”跨度空间']" :key="index">
            <div class="swiper_item">
              <div :style="{ backgroundImage: 'url(' + 'https://socialize.zkyunteng.cn/fronts/website/product/society/1-' + index + '.png)' }"></div>
              <p>{{ item }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <introduce title="传统文本预案难题" :lists="introduceLists"></introduce>

    <zk-swiper id="zkSwiper" title="三维数字化预案优势" :lists="swiperLists"></zk-swiper>
    <shuffl id="zkSwiperShuffl" title="三维数字化预案优势" :lists="swiperLists2"></shuffl>

    <div class="platform-function">
      <h2>平台功能视图</h2>
      <img src="https://socialize.zkyunteng.cn/fronts/website/product/society/0.png" alt="平台功能视图" />
    </div>

    <shuffl title="功能演示" :lists="shuffleLists"></shuffl>

    <div>
      <h2>三维预案推演与制作</h2>
      <p>
        基于企业电子沙盘（建筑物三维结构+消防安全基础数据），社会单位安全管理人员可模拟灾情发生情形，按照单位实际情况设计灭火自救预案脚本，覆盖灾情通知、处置策略发送、人员疏散与救治、控火灭火力量部署等核心步骤。推演过程可固化为企业的应急预案
      </p>

      <ul>
        <li class="society-footer_swiper_item" v-for="(item, index) in ['灾情假定', '处置指引发送', '报警与通知']" :key="index">
          <img :src="'https://socialize.zkyunteng.cn/fronts/website/product/society/society-' + index + '.png'" :alt="item" />
          <div>{{ item }}</div>
        </li>
      </ul>

      <div class="society-container_swiper_2 swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in ['灾情假定', '处置指引发送', '报警与通知']" :key="index">
            <div class="society-footer_swiper_item">
              <img :src="'https://socialize.zkyunteng.cn/fronts/website/product/society/society-' + index + '.png'" :alt="item" />
              <div>{{ item }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const host = 'https://socialize.zkyunteng.cn/fronts/website/product/society/'

import ZkSwiper from '@/components/Swiper'
import Shuffl from './components/Shuffl'
import Introduce from './components/Introduce'

import Swiper, { Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

const Swiper0 = `${host}swiper-0.png`
const Swiper1 = `${host}swiper-1.png`
const Swiper2 = `${host}swiper-2.png`

const getFunctionDemoImage = index => `${host}functional-demo-${index}.png`

// import function0 from '@/assets/images/product/'

export default {
  name: 'Society',

  components: {
    ZkSwiper,
    Shuffl,
    Introduce
  },

  data() {
    return {
      swiperLists: [
        {
          img: Swiper0,
          title: '动态',
          subTitle: '三维数字化预案优势',
          descList: ['安全基础数据电子化、动态化', '消防监控设备预警实时触发，设备状态动态上报', '企业应急策略跟随灾情发展联动']
        },
        {
          img: Swiper1,
          title: '形象',
          subTitle: '三维数字化预案优势',
          descList: ['消防安全基础数据与建筑物三维模型叠加，构造灭火救援数字孪生环境', '应急动作与策略通过三维场景演示，直观易懂']
        },
        {
          img: Swiper2,
          title: '可操作',
          subTitle: '三维数字化预案优势',
          descList: ['可基于三维数字化预案实时监控社会单位危险部位', '可基于三维数字化预案模拟灾情发生后的处置策略', '可基于三维数字化预案开展应急演练与应急培训']
        }
      ],

      swiperLists2: [],

      shuffleLists: [
        {
          img: getFunctionDemoImage(0),
          title: '安全基础数据采集治理',
          subTitles: [
            '基于防火灭火的标准战法，建立社会单位应急消防安全数据模型和采集规范，分为单位基本信息、建筑物分区及结构、消防设施、重点部位、危化品、关键图示、消防档案七大板块，基础数据可在系统快速录入，并通过三维模型实现数据与位置的可视化关联。移动端提供了对位置数据的精准采集和基础数据移动校验的能力'
          ]
        },
        {
          img: getFunctionDemoImage(1),
          title: '安全数据一张图',
          subTitles: [
            '从宏观到微观逐层展示',
            '1）社会单位的周边通道、建筑、水源',
            '2）社会单位的概况、人数、占地面积、高度、建筑面积、总体消防设施',
            '3）分区、楼层、重点部位的位置、范围、通道出口、避难所、危险物质、工艺流程、逃生路线、消防设施'
          ]
        },
        {
          img: getFunctionDemoImage(2),
          title: '实时应急预案联动',
          subTitles: [
            '接入社会单位重点部位的自动报警系统和视频监控系统，并关联已经制作好的预案脚本。监控设备一旦发现火警，系统自动弹出关联的视频监控，人工确认火警后，系统自动激发内置的预案脚本，进行灾情广播和处置策略推送。数字化预案平台由预设场景往实战方向推进'
          ]
        },
        {
          img: getFunctionDemoImage(3),
          title: '应急安全教育',
          subTitles: ['社会单位固化下来的三维数字化预案可以按流程生成在线图册，下发至单位内部人员，单位内部人员通过手机即可浏览灾情处置全程和注意事项，提升应急处理知识']
        }
      ],

      introduceLists: [
        ['静态', '文本预案中社会单位的基础数据一年或多年才更新一次，数据更新速度跟不上社会单位本身发展的实际情况，且数据更新无法及时同步到应急救援单位'],
        ['抽象', '单位建筑物平面图看懂难、专业动作术语理解难，文本预案无法还原真实场景'],
        ['实用性差', '文本预案当前仅用在备案，对社会单位灾害预警、应急事故推演、应急战术演练等方面帮助不大']
      ]
    }
  },

  mounted() {
    this.swiperLists2 = this.swiperLists.map(item => ({
      img: item.img,
      title: item.title,
      subTitles: [item.subTitle, ...item.descList]
    }))

    this.$nextTick(() => {
      this.initSwiper()
    })
  },

  methods: {
    initSwiper() {
      Swiper.use([Navigation])
      ;[0, 1, 2].forEach((item) => {
        new Swiper(`.society-container_swiper_${item}`, {
          grabCursor: true,
          loop: true,
          centeredSlides: true,
          centeredSlidesBounds: true,
          slidesPerView: 1.2
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.society-container {
  color: rgba($color: #000000, $alpha: 0.8);

  h2 {
    font-size: 32px;
  }

  p {
    line-height: 1.5;
  }

  & > div {
    padding: 70px 30px;
    box-sizing: border-box;
    @include flex-center(center, column);
  }

  & > div:nth-child(1) {
    & > p {
      margin-top: 24px;
      text-indent: 2em;
    }
    h3 {
      font-size: 24px;
      font-weight: bold;
      margin-top: 50px;
      &:last-of-type {
        margin-top: 100px;
      }
    }

    ul {
      margin-top: 24px;
      @include flex-center(center);
      flex-wrap: wrap;
      &:first-child {
        margin-bottom: 102px;
      }
    }

    .swiper_item {
      @include size(382px, 240px);
      margin-right: 23px;
      position: relative;
      div {
        @include size(382px, 240px);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        transition: background 0.2s ease;
        &:hover {
          background-size: 120% 120%;
        }
      }

      p {
        @include size(100%, 46px);
        line-height: 46px;
        text-align: center;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.62) 47%, #000000 100%);
        color: rgba($color: #fff, $alpha: 0.95);
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .swiper-container {
      display: none;
      width: 100%;
      padding-top: 16px;
      padding-bottom: 30px;

      .swiper-slide {
        width: 80%;
      }

      .swiper_item {
        @include size(299px, 188px);
        margin-right: 13px;
        div {
          @include size(299px, 188px);
          &:hover {
            background-size: 120% 120%;
          }
        }
      }
    }
  }

  .platform-function {
    img {
      margin-top: 35px;
      @include size(1053px, 426px);
    }
  }

  & > div:last-child {
    p {
      max-width: 664px;
      margin-top: 24px;
      text-align: center;
      text-indent: 2em;
      color: rgba($color: #000000, $alpha: 0.6);
    }

    ul {
      margin-top: 48px;
      @include flex-center(center);
    }

    .society-footer_swiper_item {
      width: 382px;
      margin-right: 24px;
      overflow: hidden;
      box-shadow: 2px 2px 8px 0px rgba(192, 192, 192, 0.4);

      img {
        @include size(100%, 217px);
        transition: transform 0.2s ease;
      }

      div {
        @include size(100%, 45px);
        background-color: #fff;
        line-height: 45px;
        text-align: center;
        color: rgba(0, 0, 0, 0.95);
        position: relative;
        z-index: 2;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    .society-container_swiper_2 {
      @include size(100%, 211px);
      display: none;
      padding-top: 16px;

      .swiper-slide {
        width: 80%;
      }
    }
  }

  .society-top {
    text-align: center;
    background-color: #f8fafc;

    ul {
      margin-top: 23px;
      @include flex-center(center);
      align-items: flex-start;
      flex-wrap: wrap;

      li {
        width: 368px;
        margin-top: 50px;
        color: rgba($color: #000000, $alpha: 0.6);
        margin-right: 48px;
        text-align: left;

        p:first-child {
          font-size: 18px;
          text-align: center;
          font-weight: 700;
          margin-bottom: 24px;
        }
      }
    }
  }

  #zkSwiperShuffl {
    display: none;
  }

  // 媒体查询
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 20px;
    }

    & > div {
      padding: 40px 0 30px;
    }

    & > div:nth-child(1) {
      padding-bottom: 0;
      & > p {
        width: 81.73%;
        text-align: center;
        margin-top: 13px;
      }
      ul {
        display: none;
      }
      h3 {
        margin-top: 30px;
        &:last-of-type {
          margin-top: 30px;
        }
      }
      .swiper-container {
        display: block;
      }
    }

    #zkSwiper {
      display: none;
    }

    #zkSwiperShuffl {
      display: flex;
    }

    & > div:last-child {
      p {
        max-width: 86.8%;
        margin-top: 15px;
      }

      ul {
        display: none;
      }

      .society-container_swiper_2 {
        display: block;
        .society-footer_swiper_item {
          width: 298px;
          margin-right: 12px;
          img {
            height: 158px;
          }
        }
      }
    }

    .platform-function img {
      @include size(auto, 140px);
      margin-top: 16.5px;
    }

    /deep/ .introduce {
      padding: 40px 20px 30px;
    }
  }
}

.function-swiper {
  & > div {
    @include flex-center(center);
  }

  .swiper-container {
    @include size(928px, 327px);
    margin-top: 69px;
    background-color: #fff;

    .swiper-slide {
      @include size(928px, 327px);
      @include flex-center(center);
      align-items: flex-start;
      background-color: #fff;
      img {
        @include size(541px, 327px);
        margin-right: 41px;
      }

      & > div {
        margin-top: 20px;
        line-height: 1.5;
        text-align: left;
        justify-content: flex-start;

        span {
          display: inline-block;
          @include size(100%, 2px);
          margin: 20px 0;
          background-color: #f0f0f0;
          position: relative;

          &::before {
            content: '';
            @include size(110px, 2px);
            background-color: #005eef;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        h4 {
          font-size: 28px;
        }
      }
    }
  }

  &_pre,
  &_next {
    @include size(44px);
    border: 2px solid #005eef;
    border-radius: 50%;
    position: relative;
    margin-top: 60px;
    cursor: pointer;

    &::before {
      content: '';
      @include size(25px);
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12.5px;
      margin-left: -14.5px;
      background-color: #005eef;
      clip-path: polygon(38% 16%, 79% 50%, 39% 85%, 55% 50%);
    }

    &:hover {
      border-color: #032d57;

      &::before {
        background-color: #032d57;
      }
    }
  }

  &_pre {
    transform: rotate(180deg);
    margin-right: 57px;
  }

  &_next {
    margin-left: 114px;
  }
}
</style>
