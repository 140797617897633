<template>
  <div id="notFound" class="not-found">
    <p>
      <span>4</span>
      <span>0</span>
      <span>4</span>
    </p>

    <p>页面找不到了</p>
    <div>您可以 <span @click="onBack">返回上一页</span> 或者 <span @click="goHome">网站首页</span></div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      this.$Bus.$emit('change.menu.background', true)
    })
  },

  beforeDestroy() {
    this.$Bus.$emit('change.menu.background', false)
  },

  methods: {
    onBack() {
      try {
        this.$router.back()
      } catch (error) {
        this.goHome()
      }
    },

    goHome() {
      this.$router.replace({ path: '/' })
    }
  }
}
</script>

<style lang="scss">
.not-found {
  height: 100vh;
  background-color: #e9e9e9;
  padding-top: 150px;
  p:first-child {
    text-align: center;
    font-family: cursive;
    font-size: 150px;
    font-weight: bold;
    line-height: 100px;
    letter-spacing: 5px;
    color: #fff;

    span {
      cursor: pointer;
      text-shadow: 0 0 2px #686868, 0 1px 1px #ddd, 0 2px 1px #d6d6d6, 0 3px 1px #ccc, 0 4px 1px #c5c5c5, 0 5px 1px #c1c1c1, 0 6px 1px #bbb, 0 7px 1px #777, 0 8px 3px rgba(100, 100, 100, 0.4),
        0 9px 5px rgba(100, 100, 100, 0.1), 0 10px 7px rgba(100, 100, 100, 0.15), 0 11px 9px rgba(100, 100, 100, 0.2), 0 12px 11px rgba(100, 100, 100, 0.25), 0 13px 15px rgba(100, 100, 100, 0.3);
      -webkit-transition: all 0.1s linear;
      transition: all 0.1s linear;
      &:hover {
        text-shadow: 0 0 2px #686868, 0 1px 1px #fff, 0 2px 1px #fff, 0 3px 1px #fff, 0 4px 1px #fff, 0 5px 1px #fff, 0 6px 1px #fff, 0 7px 1px #777, 0 8px 3px #fff, 0 9px 5px #fff, 0 10px 7px #fff,
          0 11px 9px #fff, 0 12px 11px #fff, 0 13px 15px #fff;
      }
    }
  }
  p:not(:first-child) {
    text-align: center;
    color: #666;
    font-size: 20px;
    text-shadow: 0 1px 0 #fff;
    letter-spacing: 1px;
    line-height: 2em;
    margin-top: 50px;
  }

  div {
    font-size: 14px;
    text-align: center;
    span {
      cursor: pointer;
      color: #54a5cd;
      &:hover {
        color: #116d9b;
      }
    }
  }
}
</style>
